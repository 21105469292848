// ManageUsers.js

import React, { useState, useEffect } from 'react';
import { getAllDataFromDB, deleteUserFromDB } from '../utils/dbUtilities';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const fetchedUsers = await getAllDataFromDB('users');
      setUsers(fetchedUsers.map(user => ({
        ...user,
        // Convert Firestore timestamp to milliseconds if necessary
        creationTime: user.creationTime && typeof user.creationTime.toDate === 'function'
          ? user.creationTime.toDate().getTime()
          : user.creationTime,
      })));
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        await deleteUserFromDB(userId);
        fetchUsers(); // Refresh users list after deletion
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'No date available';
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div>
      <h2>Manage Users</h2>
      <div>Total Registered Users: {users.length}</div>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>First Name</th>
            <th>Account Creation Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.firstName}</td>
              <td>{formatDate(user.creationTime)}</td>
              <td>
                <button onClick={() => handleDelete(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageUsers;
