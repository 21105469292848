// NewPosts.js

import React, { useContext, useEffect } from 'react';
import Post from './Post';
import PostsContext from '../contexts/PostsContext';
import { useAuth } from '../contexts/AuthContext';
import { getAllDataFromDB } from '../utils/dbUtilities'; // Import the function from your utilities
import './HomePage.css';

const NewPosts = () => {
  const { posts, setPosts } = useContext(PostsContext);
  const { user: authUser } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      if (!authUser) return;

      try {
        // Fetch posts from Firestore
        const fetchedPosts = await getAllDataFromDB('posts');
        // Sort posts by createdAt field in descending order to show newest posts first
        const sortedPosts = fetchedPosts.sort((a, b) => b.createdAt - a.createdAt);
        // Deduplicate posts based on post.id
        const uniqueSortedPosts = Array.from(new Map(sortedPosts.map(post => [post.id, post])).values());
        
        setPosts(uniqueSortedPosts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPosts();
  }, [authUser, setPosts]); // Removed dependencies unrelated to fetching posts

  return (
    <div className="posts" style={{ paddingTop: '70px' }}>
      {posts.map(post => (
        <div key={post.id} className="post-container">
          <Post {...post} />
        </div>
      ))}
    </div>
  );
};

export default NewPosts;
