// Sidebar.js

import React, { useContext, useEffect } from 'react';
import { FaHouseUser, FaCompass, FaSignOutAlt, FaPlusSquare, FaTshirt, FaUserPlus, FaEnvelope, FaCog } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import InfoIcon from '@mui/icons-material/Info';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import CloseButton from './CloseButton';
import { useAuth } from '../contexts/AuthContext';
import ActiveItemContext from '../contexts/ActiveItemContext';
import { saveDataToDB } from '../utils/dbUtilities';

const Sidebar = ({ isVisible, setIsSidebarVisible }) => {
  const navigate = useNavigate();
  const { isLoggedIn, currentUser: authUser, logout } = useAuth();
  const { activeItem, setActiveItem } = useContext(ActiveItemContext);

  useEffect(() => {
    if (authUser && !authUser.displayName && authUser.reload) {
      authUser.reload();
    }
  }, [authUser]);

  const username = authUser ? (authUser.username || authUser.displayName || authUser.email.split('@')[0]) : 'HyroVault';

  const handleLogoutClick = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const baseMenuItems = [
    { id: 'Home', icon: <FaHouseUser className="sidebar-icon" />, link: '/' },
    { id: 'Post', icon: <FaPlusSquare className="sidebar-icon" />, link: '/post' },
    { id: 'New Posts', icon: <FiberNewIcon className="sidebar-icon" />, link: '/newposts' },
    { id: 'Explore', icon: <FaCompass className="sidebar-icon" />, link: '/explore' },
    { id: 'Info', icon: <InfoIcon className="sidebar-icon" />, link: '/info' },
    // Removed the Profile menu item
    { id: 'Contact', icon: <FaEnvelope className="sidebar-icon" />, link: '/contact' }
  ];

  const loggedInMenuItems = [
    { id: 'Logout', icon: <FaSignOutAlt className="sidebar-icon" />, onClick: handleLogoutClick }
  ];

  const loggedOutMenuItems = [
    { id: 'Sign Up', icon: <FaUserPlus className="sidebar-icon" />, link: '/signup' },
    { id: 'Login', icon: <FaSignOutAlt className="sidebar-icon" />, link: '/login' }
  ];

  const menuItems = isLoggedIn ? [...baseMenuItems, ...loggedInMenuItems] : [...baseMenuItems, ...loggedOutMenuItems];

  const adminMenuItem = { 
    id: 'Admin Dashboard', 
    icon: <FaCog className="sidebar-icon" />, 
    link: '/admin-dashboard' 
  };

  const handleItemClick = async (item) => {
    if (window.innerWidth <= 768) {
      setIsSidebarVisible(false);
      document.body.classList.remove('sidebar-open');
    }
  
    if (!isLoggedIn && ['Post', 'Explore', 'Profile'].includes(item.id)) {
      navigate('/login');
      setActiveItem('Login');
      return;
    }
  
    setActiveItem(item.id);
  
    if (item.link) {
      navigate(item.link);
    } else if (item.onClick) {
      item.onClick();
    }
  
    if (authUser) {
      try {
        await saveDataToDB('userSettings', { activeItem: item.id }, authUser.uid);
      } catch (error) {
        console.error('Error saving active item:', error);
      }
    }
  };    

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isVisible]);

  const SidebarItem = ({ item }) => {
    const isNewPostsItem = item.id === 'New Posts';
    return (
      <li className={`sidebar-item ${isNewPostsItem ? 'sidebar-item--new-posts' : ''} ${activeItem === item.id ? 'active' : ''}`}>
        <Link to={item.link || '#'} className="sidebar-link" onClick={(e) => {
            e.preventDefault(); // Prevent default action to ensure link behavior is controlled
            handleItemClick(item);
          }}>
          <div className="sidebar-link-content">
            <div className="sidebar-icon-wrapper">{item.icon}</div>
            <span className="sidebar-text">{item.id}</span>
          </div>
        </Link>
      </li>
    );
  };  

  return (
    <div className={`sidebar ${isVisible ? 'open' : ''}`}>
      <div className="sidebar-close-button">
        <CloseButton onClick={() => {
          if (window.innerWidth <= 768) {
            setIsSidebarVisible(false);
            document.body.classList.remove('sidebar-open');
          }
        }} />
      </div>
      <div className="profile-section">
        <Link to="/" className="profile-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={(e) => {
            e.preventDefault(); // Prevent the default link action
            if (window.innerWidth <= 768) {
              setIsSidebarVisible(false);
            }
            navigate('/');
        }}>
          <img src={`${process.env.PUBLIC_URL}/upscaled_hyrovault_logo.png`} alt="App Logo" className="profile-pic" />
          <div className="profile-name-container">
            <h3 className="profile-name">{username}</h3>
          </div>
        </Link>
      </div>
      <nav>
        <ul className="sidebar-menu">
          {menuItems.map(item => <SidebarItem key={item.id} item={item} />)}
          {authUser && authUser.role === 'admin' && <SidebarItem item={adminMenuItem} />}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
