// MessagesList.js

import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getChatPartners, generateConversationId, getMessagesByConversationId } from '../utils/dbUtilities';
import { onSnapshot, query, collection, where } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../utils/firebaseConfig';
import './MessagesList.css';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, CircularProgress, Typography, Card, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { grey } from '@mui/material/colors';
import Fade from '@mui/material/Fade';
import { timeAgo } from '../utils/timeFormatter';

const MessagesList = () => {
  const { currentUser: authUser, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [userLatestMessage, setUserLatestMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const [unsubscribeFunctions, setUnsubscribeFunctions] = useState([]);

  useEffect(() => {
    if (authLoading || !authUser) return;
  
    const fetchAndUpdateMessages = async () => {
      setLoading(true);
      const chatPartners = await getChatPartners(authUser.displayName);
      let latestMessages = {};
  
      const unsubscribeFunctions = chatPartners.map(partner => {
        const conversationId = generateConversationId(authUser.displayName, partner);
        return getMessagesByConversationId(conversationId, (messages) => {
          let latestMessageForConversation = null;
          messages.forEach(message => {
            const isMessageUnread = message.sender !== authUser.displayName && !message.read;
            if (!latestMessageForConversation || new Date(latestMessageForConversation.timestamp) < new Date(message.timestamp)) {
              latestMessageForConversation = { ...message, unread: isMessageUnread };
            }
          });
  
          if (latestMessageForConversation) {
            const partnerUsername = latestMessageForConversation.sender === authUser.displayName ? latestMessageForConversation.receiver : latestMessageForConversation.sender;
            latestMessages[partnerUsername] = latestMessageForConversation;
          }
          setUserLatestMessage({ ...latestMessages });
        });
      });
  
      setUnsubscribeFunctions(unsubscribeFunctions);
      setLoading(false);
    };
  
    fetchAndUpdateMessages();
  
    return () => {
      unsubscribeFunctions.forEach(unsubscribe => unsubscribe());
    };
  }, [authUser, authLoading]);  

  const handleOpenChat = (username) => {
    navigate(`/chat/${username}`);
  };

  const getMessagePreview = (message) => {
    return message.image ? 'Image' : truncateMessage(message.text);
  };

  const truncateMessage = (message, maxLength = 30) => {
    return message.length > maxLength ? message.substring(0, maxLength) + '...' : message;
  };

  if (authLoading || loading) {
    return <div className="messages-loading"><CircularProgress /></div>;
  }

  if (!authUser) {
    return <div>User not authenticated. Please log in.</div>;
  }

  return (
    <Fade in={!loading}>
      <List className="messages-list-container">
        {Object.keys(userLatestMessage).length > 0 ? (
          Object.entries(userLatestMessage).map(([username, msg]) => (
            <Tooltip title="Open chat" placement="right" arrow key={username}>
              <Card variant="outlined" sx={{ marginBottom: 2, bgcolor: grey[100] }}>
                <ListItem button onClick={() => handleOpenChat(username)} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar src={msg.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={username}
                    secondary={getMessagePreview(msg)}
                    primaryTypographyProps={{ fontWeight: msg.unread ? 'bold' : 'normal' }}
                    secondaryTypographyProps={{ component: 'span', variant: 'body2', color: 'text.primary', fontWeight: msg.unread ? 'bold' : 'normal' }}
                  />
                  <Typography variant="caption" sx={{ marginLeft: 'auto', alignSelf: 'center' }}>
                    {timeAgo(msg.timestamp)}
                  </Typography>
                  <IconButton edge="end" aria-label="messages" onClick={() => handleOpenChat(username)}>
                    <CommentIcon />
                  </IconButton>
                </ListItem>
              </Card>
            </Tooltip>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No messages to display." />
          </ListItem>
        )}
      </List>
    </Fade>
  );
};

export default MessagesList;