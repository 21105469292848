// EditPostForm.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';
import { IoMdCloudUpload, IoMdImages } from 'react-icons/io';
import './PostForm.css';
import { uploadImageToStorage, getPostById, updatePostInDB } from '../utils/dbUtilities';
import PostsContext from '../contexts/PostsContext';
import CloseButton from './CloseButton';

function MyDropzone({ existingImageUrl, onImageUpload, setIsImageProcessing, setProgress, setUploadError }) {
    const [selectedImage, setSelectedImage] = useState(existingImageUrl);
    const [localError, setLocalError] = useState('');

    useEffect(() => {
        // If there's an existing image URL, use it for the preview
        if (existingImageUrl) {
            setSelectedImage(existingImageUrl instanceof Blob ? URL.createObjectURL(existingImageUrl) : existingImageUrl);
        }
      }, [existingImageUrl]);

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const invalidFileTypes = ['video/mp4', 'audio/wav', 'image/gif', 'video/x-ms-wmv'];

    const checkFileValidity = (file) => {
        if (invalidFileTypes.includes(file.type)) {
            const errorMsg = 'Invalid file type. MP4, WAV, GIF, and WMV files are not allowed.';
            setUploadError(errorMsg);
            setLocalError(errorMsg);
            setIsImageProcessing(false);
            setProgress(0);
            return false;
        }

        if (file.size > MAX_FILE_SIZE) {
            const errorMsg = 'File size exceeds 5 MB limit.';
            setUploadError(errorMsg);
            setLocalError(errorMsg);
            setIsImageProcessing(false);
            setProgress(0);
            return false;
        }

        setUploadError('');
        setLocalError('');
        return true;
    };

    const convertImageToJPG = async (file) => {
        return new Promise(async (resolve, reject) => {
            if (file.type === 'image/heic') {
                try {
                    const convertedBlob = await heic2any({
                        blob: file,
                        toType: "image/jpeg",
                        quality: 0.92
                    });
                    setProgress(50);
                    resolve(convertedBlob);
                } catch (error) {
                    console.error("Error converting image:", error);
                    reject(error);
                }
            } else {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    canvas.toBlob(blob => {
                        setProgress(70);
                        resolve(blob);
                    }, 'image/jpeg', 0.92);
                };
                img.onerror = reject;
                img.src = URL.createObjectURL(file);
            }
        });
    };

    const removeImage = () => {
        setSelectedImage(null);
        setUploadError('');
        onImageUpload(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: async (acceptedFiles) => {
            setIsImageProcessing(true);
            setProgress(5);
            const file = acceptedFiles[0];
           
            if (!checkFileValidity(file)) {
                return;
            }
       
            try {
                const convertedImageBlob = await convertImageToJPG(file);
                setProgress(80);
                const objectURL = URL.createObjectURL(convertedImageBlob);
                setSelectedImage(objectURL); // Ensure the selectedImage state is updated with the new preview URL
                onImageUpload(convertedImageBlob); // Pass the Blob to the parent component for further handling
                setIsImageProcessing(false);
            } catch (error) {
                console.error("Error processing image", error);
                setIsImageProcessing(false);
            }
        }        
    });

    return (
        <div className="image-upload-section">
            {selectedImage ? (
                <div className="image-preview-container">
                    <img src={selectedImage} alt="Uploaded preview" className="uploaded-image-preview" />
                    <span className="remove-image-icon" onClick={removeImage}>X</span>
                </div>
            ) : (
                <div {...getRootProps()} className="drag-drop-zone">
                    <input {...getInputProps()} />
                    <IoMdCloudUpload size={50} className="upload-icon" />
                    <p>Drag & Drop or Click Here to Upload Image (5 MB Size Limit)</p>
                    <IoMdImages size={50} className="images-icon" />
                </div>
            )}
            {localError && <p className="error-message">{localError}</p>}
        </div>
    );
}

function EditPostForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { updateSinglePost } = useContext(PostsContext);
   
    const [formState, setFormState] = useState({
        location: '',
        conditions: [],
        image: '',
        title: '',
        price: '',
        city: '',
        state: '',
        country: '',
        originalTimestamp: null
    });
    const [isImageProcessing, setIsImageProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadError, setUploadError] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [formErrors, setFormErrors] = useState({
        titleError: false,
        priceError: false,
        conditionError: false,
        locationError: false,
        cityError: false,
        countryError: false,
    });

    useEffect(() => {
        // Scrolls to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getPostById(id);
            if (data) {
                setFormState({
                    ...data,
                    image: data.imageUrl || '',
                    location: data.checkboxLocation || '',
                    originalTimestamp: data.timestamp?.seconds ? new Date(data.timestamp.seconds * 1000) : data.timestamp
                });
            } else {
                navigate('/');
            }
        };

        fetchData();
    }, [id, navigate]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({ ...prevState, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        setFormState(prevState => {
            const newConditions = checked
                ? [...prevState.conditions, value]
                : prevState.conditions.filter(c => c !== value);
            return { ...prevState, conditions: newConditions };
        });
    };

    const handleRadioChange = (e) => {
        setFormState(prevState => ({ ...prevState, location: e.target.value }));
    };

    const handleImageUpload = (blobOrUrl) => {
        let imageUrlToUpdate = blobOrUrl;
        if (blobOrUrl instanceof Blob) {
            // If it's a Blob, we need to create an object URL for preview purposes only
            // Do not set this as the imageUrl to be saved in Firestore
            imageUrlToUpdate = URL.createObjectURL(blobOrUrl);
        }
        setFormState(prevState => ({
            ...prevState,
            image: blobOrUrl // Keep the Blob or URL as is to decide later in handleSubmit
        }));
    }; 

    const validateForm = () => {
        const errors = {
            titleError: !formState.title,
            priceError: isNaN(formState.price) || formState.price === '',
            conditionError: formState.conditions.length === 0,
            locationError: !formState.location,
            cityError: formState.city === '',
            countryError: formState.country === '',
        };

        // Update formErrors state
        setFormErrors(errors);

        // Check if any errors are true, which indicates validation failure
        return !Object.values(errors).some(error => error);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!validateForm()) {
            console.error("Form validation failed.");
            return;
        }
    
        setIsEditing(true);
        let imageUrl = formState.image;
        if (formState.image instanceof Blob) {
            console.log("New image detected, starting upload...");
            try {
                imageUrl = await uploadImageToStorage(formState.image, `posts/${id}/${new Date().toISOString()}`);
                // No need to set imageUrl in formState here since we're about to update Firestore and navigate away
            } catch (error) {
                console.error("Error uploading new image:", error);
                setIsEditing(false);
                return;
            }
        }
    
        const updatedData = {
            ...formState,
            imageUrl: typeof imageUrl === 'string' ? imageUrl : formState.imageUrl // Ensure imageUrl is a string
        };
        delete updatedData.image; // Clean up: remove the Blob or temporary URL from the form state before updating Firestore
    
        try {
            await updatePostInDB(id, updatedData);
            console.log('Post updated successfully in Firestore with imageUrl:', imageUrl);
            updateSinglePost({ ...updatedData, id }); // Ensure the local state is updated if you're using context or Redux
            navigate(`/posts/${id}`);
        } catch (error) {
            console.error("Error updating post:", error);
        } finally {
            setIsEditing(false);
        }
    };  

    return (
        <div className="form-container">
            <CloseButton onClick={() => navigate(-1)} />
            <h2 className="form-title">Edit Post</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>What are you looking for?</label>
                    <input type="text" name="title" className="form-input" placeholder="e.g. Vintage Record Player" value={formState.title} onChange={handleFieldChange} />
                    {formErrors.titleError && <p className="error-message">Please enter what you are looking for.</p>}
                </div>
                <div className="form-group">
                    <label>How much are you willing to pay? (including shipping)</label>
                    <input type="number" name="price" className="form-input" placeholder="e.g. $50" value={formState.price} onChange={handleFieldChange} />
                    {formErrors.priceError && <p className="error-message">Please enter a valid price.</p>}
                </div>
                <div className="form-group">
                    <label>Acceptable condition(s)?</label>
                    <div className="form-options">
                        {['Fair', 'Good', 'Excellent', 'Brand New'].map(condition => (
                            <div key={condition}>
                                <input
                                    type="checkbox"
                                    id={condition.toLowerCase()}
                                    name="conditions"
                                    value={condition}
                                    checked={formState.conditions.includes(condition)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={condition.toLowerCase()}>{condition}</label>
                            </div>
                        ))}
                    </div>
                    {formErrors.conditionError && <p className="error-message">Please select at least one condition.</p>}
                </div>
                <div className="form-group">
                <label>Where do you live?</label>
                <div className="form-options">
                    {['North America', 'Europe', 'Africa', 'Middle East', 'Asia', 'Central America', 'South America', 'Australia'].map(location => {
                        console.log("Radio Button: ", location, "Form State Location: ", formState.location); // Add this line
                        return (
                            <div key={location}>
                                <input
                                    type="radio"
                                    id={location.replace(/\s+/g, '').toLowerCase()}
                                    name="location"
                                    value={location}
                                    checked={formState.location === location}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor={location.replace(/\s+/g, '').toLowerCase()}>{location}</label>
                            </div>
                        );
                    })}
                </div>
                {formErrors.locationError && <p className="error-message">Please select your location.</p>}
            </div>
                <div className="form-group">
                    <label>City</label>
                    <input type="text" name="city" className="form-input" value={formState.city} onChange={handleFieldChange} />
                    {formErrors.cityError && <p className="error-message">Please enter your city.</p>}
                </div>
                <div className="form-group">
                    <label>State (optional)</label>
                    <input type="text" name="state" className="form-input" value={formState.state} onChange={handleFieldChange} />
                </div>
                <div className="form-group">
                    <label>Country</label>
                    <input type="text" name="country" className="form-input" value={formState.country} onChange={handleFieldChange} />
                    {formErrors.countryError && <p className="error-message">Please enter your country.</p>}
                </div>
                <MyDropzone
                    existingImageUrl={formState.image}
                    onImageUpload={handleImageUpload} // Pass this function as a prop
                    setIsImageProcessing={setIsImageProcessing}
                    setProgress={setProgress}
                    setUploadError={setUploadError}
                />
                {uploadError && <p className="error-message">{uploadError}</p>}
                {isImageProcessing && (
                    <div className="progress-bar-container">
                        <progress value={progress} max="100"></progress>
                    </div>
                )}
            <button
                type="submit"
                className="form-button"
                disabled={isImageProcessing || isEditing} // Removed check for !formState.image to allow submissions without image changes
            >
                    {isEditing ? 'Saving Changes...' : 'Save Changes'}
                </button>
            </form>
        </div>
    );
    }
   
    export default EditPostForm;

