// LikesPage.js

import React, { useContext, useEffect, useState } from 'react';
import Post from './Post';
import { FaHeart } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import PostsContext from '../contexts/PostsContext';
import { useLikes } from '../contexts/LikesContext';
import './LikesPage.css';

const LikesPage = () => {
  const { user } = useAuth();
  const { posts } = useContext(PostsContext);
  const { userLikes } = useLikes();
  const [topMargin, setTopMargin] = useState('40px');

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts

    // Adjust the top margin based on screen width
    const updateTopMargin = () => {
      if (window.innerWidth === 1264) {
        setTopMargin('100px'); // Set specific top margin for screen width 1264px
      } else if (window.innerWidth <= 768) {
        setTopMargin('160px'); // Increased top margin for iPad Mini and similar screen sizes
      } else {
        setTopMargin('80px'); // Ensure top margin for larger screens
      }
    };

    // Listen for window resize events
    window.addEventListener('resize', updateTopMargin);

    // Set the initial margin based on the current window size
    updateTopMargin();

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', updateTopMargin);
  }, []);

  // Filter the posts to only include those liked by the user
  const likedPosts = posts.filter(post => userLikes.includes(post.id));

  return (
    <div className="likes-page" style={{ marginTop: topMargin }}>
      <h1 className="page-heading"><FaHeart /> Likes</h1>
      {likedPosts.length > 0 ? (
        likedPosts.map(post =>
          <div key={post.id} className="post-container">
            <Post 
              id={post.id}
              title={post.title}
              author={post.author}
              price={post.price}
              conditions={post.conditions}
              location={post.location}
              image={post.image}
              timestamp={post.timestamp}
              city={post.city}             
              state={post.state}           
              country={post.country}       
              checkboxLocation={post.checkboxLocation} 
            />
          </div>
        )
      ) : (
        <p>You haven't liked any posts yet.</p>
      )}
    </div>
  );
};

export default LikesPage;

