// PostVerification.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import PostForm from './PostForm';
import { getPostVerificationPassword } from '../utils/dbUtilities';

const PostVerification = () => {
  const [enteredPassword, setEnteredPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [postVerificationPassword, setPostVerificationPassword] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!currentUser) {
      navigate('/login');
    }

    async function fetchPassword() {
      try {
        const password = await getPostVerificationPassword();
        setPostVerificationPassword(password || 'secondpost');
      } catch (error) {
        console.error('Error fetching post verification password:', error);
      }
    }

    fetchPassword();
  }, [currentUser, navigate]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === postVerificationPassword) {
      setIsPasswordCorrect(true);
    } else {
      setPasswordError('Incorrect password. Please try again.');
    }
  };

  if (isPasswordCorrect) {
    return <PostForm />;
  }

  return (
    <div className="form-container password-verification">
      <h2 className="form-title password-verification-title">What's the Invite Password to Post? (Password: firstpost)</h2>
      <form onSubmit={handlePasswordSubmit} className="post-form password-verification-form-group">
        <div className="form-group">
          <input
            type="password"
            className="form-input password-verification-input"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            placeholder="Enter password"
            autoFocus
          />
          {passwordError && <p className="error-message">{passwordError}</p>}
        </div>
        <button type="submit" className="form-button password-verification-button">Enter</button>
      </form>
    </div>
  );
};

export default PostVerification;
