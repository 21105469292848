// getAllUsernames.js

import { db } from './firebaseConfig';
import { getDocs, collection } from 'firebase/firestore';

export const getAllUsernames = async () => {
  try {
    const usernames = [];
    const usersRef = collection(db, 'usernames');
    const snapshot = await getDocs(usersRef);
    snapshot.forEach(doc => {
      usernames.push(doc.id);  // assuming doc.id is the username
    });
    return usernames;
  } catch (error) {
    console.error("Error fetching usernames: ", error);
    return [];
  }
}