// PostPage.js

import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Card, CardActions, CardContent, CardMedia, Typography, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FaEnvelope } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import PostsContext from '../contexts/PostsContext';
import { deletePostFromDB, reportPost } from '../utils/dbUtilities';
import { timeAgo } from '../utils/timeFormatter';
import ReportForm from './ReportForm';
import ChatWindow from './ChatWindow';
import './Post.css';
import { formatPrice } from '../utils/priceFormatter';

const PostPage = () => {
    const { posts, likePostById, unlikePostById, updatePostLikeStatus } = useContext(PostsContext);
    const [showReportForm, setShowReportForm] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [showChatWindow, setShowChatWindow] = useState(false);
    const { currentUser, isLoggedIn } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 1024px)');
    const isLargeScreen = useMediaQuery('(min-width: 1024px) and (max-width: 1366px)');
    const isSurfacePro7 = useMediaQuery('(min-width: 1368px) and (max-width: 1368px)');
    const isVeryLargeScreen = useMediaQuery('(min-width: 1300px)');

    useEffect(() => {
        window.scrollTo(0, 0);

        if (posts.length === 0) {
            setIsLoading(true);
            return;
        }

        const fetchedPost = posts.find(p => p.id && p.id.toString() === id);
        if (!fetchedPost && !isLoading) {
            navigate('/404');
            return;
        }
        setPost(fetchedPost);
        setIsLoading(false);
    }, [posts, navigate, id, isLoading]);

    const handleLike = async () => {
        if (!isLoggedIn || !currentUser) {
            navigate('/login');
            return;
        }
        if (post.liked) {
            await unlikePostById(post.id);
            updatePostLikeStatus(post.id, false, post.likes - 1);
        } else {
            await likePostById(post.id);
            updatePostLikeStatus(post.id, true, post.likes + 1);
        }
    };

    const sharePost = () => {
        const url = `${window.location.origin}/posts/${id}`;
        navigator.clipboard.writeText(url).then(() => {
            setCopySuccess('Link copied!');
            setTimeout(() => setCopySuccess(''), 3000);
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const toggleReportForm = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setShowReportForm(!showReportForm);
    };

    const openChatWindow = () => {
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setShowChatWindow(true);
    };

    const handleUsernameClick = () => {
        navigate(`/usersposts/${post.author}`);
    };

    if (isLoading) {
        return null;
    }

    if (!post) {
        return null;
    }

    const locationString = `${post.city}${post.state ? `, ${post.state}` : ''}, ${post.country} (${post.checkboxLocation})`;
    const postDate = post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp);
    const isAuthor = currentUser && currentUser.displayName === post?.author;

    return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            pt: {
              xs: 15,
              sm: 18,
              md: 20,
              lg: 20,
              xl: 20,
            },
            ml: isSurfacePro7 ? '80px' : isMediumScreen ? '60px' : { sm: '140px' },
            mt: isVeryLargeScreen ? 0 : isSurfacePro7 ? 15 : isMediumScreen ? '10px' : { xs: 10, sm: 15 },
            '@media (min-width: 1264px) and (max-width: 1264px)': {
              mt: -3, // Adjust the positive margin to move the container down
            },
            '@media (min-width: 1281px) and (max-width: 1281px)': {
              mt: -3, // Adjust the positive margin to move the container down
            },
          }}
          className="post-page-container"
        >
            <Card
                className="post"
                sx={{
                    maxWidth: isSurfacePro7 ? '95%' : isMediumScreen ? '60%' : '100%',
                    mb: 2,
                    marginLeft: isSurfacePro7 ? 'auto' : isMediumScreen ? '60px' : { sm: '140px' },
                    marginRight: isSurfacePro7 ? 'auto' : '0',
                    '@media (min-width: 1264px) and (max-width: 1264px)': {
                      mt: -3, // Adjust the positive margin to move the card down
                    },
                    '@media (min-width: 1281px) and (max-width: 1281px)': {
                      mt: -3, // Adjust the positive margin to move the card down
                    },
                }}
            >
                <CardMedia
                    component="img"
                    image={post.imageUrl || "https://via.placeholder.com/720x720"}
                    alt={post.title}
                />
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'center', width: '100%' }}>
                            {post.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', width: '100%' }} onClick={handleUsernameClick}>
                            Posted {timeAgo(postDate)} by <span style={{ fontWeight: 'bold' }}>{post.author}</span>
                        </Typography>
                        <Typography variant="body1" color="text.primary" style={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                            I'll Buy This From You For {formatPrice(post.price)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', width: '100%' }}>
                            Acceptable Condition(s): {post.conditions.join(', ')}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', width: '100%' }}>
                            {locationString}
                        </Typography>
                        <Tooltip title="Share" style={{ alignSelf: 'center' }}>
                            <IconButton onClick={sharePost}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardContent>
                <CardActions disableSpacing style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
                    <div>
                        <Tooltip title={post.liked ? 'Unlike' : 'Like'}>
                            <IconButton onClick={handleLike} sx={{ color: post.liked ? 'red' : 'inherit' }}>
                                {post.liked ? <Favorite /> : <FavoriteBorder />}
                            </IconButton>
                        </Tooltip>
                        <span className="votes-count">{post.likes}</span>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {isAuthor && (
                            <>
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => navigate(`/editpost/${id}`)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this post?")) {
                                            deletePostFromDB(id);
                                            navigate('/');
                                        }
                                    }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title="Report">
                            <IconButton onClick={toggleReportForm}>
                                <OutlinedFlagIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Send Message">
                            <div onClick={openChatWindow} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <IconButton style={{ color: 'inherit' }}>
                                    <FaEnvelope size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                </IconButton>
                                <span>I Got It!</span>
                            </div>
                        </Tooltip>
                    </div>
                </CardActions>
                {showReportForm && (
                    <ReportForm 
                        postId={id} 
                        reporterId={currentUser?.uid}
                        submitReport={(reportData) => reportPost(id, currentUser?.uid, reportData)}
                        closeModal={() => setShowReportForm(false)} 
                    />
                )}
                {copySuccess && <div className="copy-success">{copySuccess}</div>}
                {showChatWindow && (
                    <ChatWindow 
                        initialRecipient={post.author} 
                        onClose={() => setShowChatWindow(false)} 
                        defaultMessage="I've got what you're looking for!" 
                    />
                )}
            </Card>
        </Box>
    );                    
};

export default PostPage;


