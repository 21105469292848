// UsersPosts.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Post from './Post';
import { FaUser } from 'react-icons/fa';
import { getAllDataFromDB } from '../utils/dbUtilities';
import './UsersPosts.css';

function UsersPosts() {
    const [userPosts, setUserPosts] = useState([]);
    const { username } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
        
        async function fetchUserPosts() {
            try {
                const allPosts = await getAllDataFromDB('posts');
                const specificUserPosts = allPosts
                    .filter(post => post.author === username)
                    .map(post => ({
                      ...post,
                      timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp);  // Sorting from newest to oldest
                setUserPosts(specificUserPosts);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        }
    
        fetchUserPosts();
    }, [username]);          

    return (
        <div className="users-posts-container">
            <h1><FaUser /> Posts by {username}</h1>
            {userPosts.map(post => 
                <Post 
                    key={post.id}
                    id={post.id}
                    title={post.title}
                    author={post.author}
                    price={post.price}
                    conditions={post.conditions}
                    location={post.location}
                    image={post.image}
                    timestamp={post.timestamp}
                    city={post.city}             // Pass the city field
                    state={post.state}           // Pass the state field
                    country={post.country}       // Pass the country field
                    checkboxLocation={post.checkboxLocation} // Pass the checkboxLocation field
                />
            )}
        </div>
    );
}

export default UsersPosts;
