/* Navbar.js */

import React, { useState, useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FaSearch, FaUser, FaEnvelope, FaBars, FaHeart, FaTimes } from 'react-icons/fa';
import './Navbar.css';

import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import ActiveItemContext from '../contexts/ActiveItemContext';
import { useMessages } from '../contexts/MessagesContext';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const NavBar = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const { isLoggedIn, currentUser } = useAuth();
  const navigate = useNavigate();
  const { setActiveItem } = useContext(ActiveItemContext);
  const { fetchMessages, unreadMessagesCount } = useMessages();

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const searchInput = document.querySelector('.search-input');

    const handleFocus = () => {
      disableBodyScroll(searchInput);
    };

    const handleBlur = () => {
      enableBodyScroll(searchInput);
    };

    searchInput.addEventListener('focus', handleFocus);
    searchInput.addEventListener('blur', handleBlur);

    // Cleanup the event listeners on component unmount
    return () => {
      searchInput.removeEventListener('focus', handleFocus);
      searchInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && currentUser) {
      fetchMessages();
    }
  }, [isLoggedIn, currentUser, fetchMessages]);

  useEffect(() => {
    console.log("NavBar re-rendered. Unread Messages Count: ", unreadMessagesCount);
  }, [unreadMessagesCount]);

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const executeSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      executeSearch();
    }
  };

  const handleRestrictedClick = (path) => {
    if (!isLoggedIn) {
      setActiveItem('Login');
      navigate('/login');
    } else {
      navigate(path);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <Container id="navbar">
      <div className="hamburger-menu" onClick={toggleSidebar}>
        <FaBars />
      </div>
      <div className="navbar__search">
        <input
          type="text"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchInput}
          onKeyPress={handleSearchKeyPress}
          placeholder="Search for 'Item Wanted' Posts"
        />
        <div className="search-icons">
          {searchTerm && (
            <FaTimes className="clear-search-icon" onClick={() => setSearchTerm('')} />
          )}
          <FaSearch className="search-icon" onClick={executeSearch} />
        </div>
      </div>
      <div className="nav-icons">
        <a href="https://www.hyrofits.com" target="_blank" rel="noopener noreferrer" className="navbar-item logo-link">
          <img src={`${process.env.PUBLIC_URL}/upscaled_hyrofits_logo.png`} alt="Hyrofits Logo" className="navbar-logo" />
        </a>
        <button onClick={() => handleRestrictedClick('/likes')} className="navbar-item">
          <FaHeart className="navbar-icon" />
          <span className="text">Likes</span>
        </button>
        <button onClick={() => handleRestrictedClick('/myposts')} className="navbar-item">
          <FaUser className="navbar-icon" />
          <span className="text">MP</span>
        </button>
        <button onClick={() => handleRestrictedClick('/messages')} className="navbar-item" style={{ position: 'relative' }}>
          <FaEnvelope className="navbar-icon" />
          <span className="text">DM</span>
          {unreadMessagesCount > 0 && <span className="unread-messages-count">{unreadMessagesCount}</span>}
        </button>
      </div>
    </Container>
  );
};

export default NavBar;


