// HomePage.js

import React, { useContext, useEffect } from 'react';
import Post from './Post';
import PostsContext from '../contexts/PostsContext';
import { useAuth } from '../contexts/AuthContext';
import './HomePage.css';

const HomePage = () => {
  const { posts } = useContext(PostsContext);
  const { user: authUser } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [authUser]);

  // Deduplicate posts based on post.id
  const uniquePosts = Array.from(new Map(posts.map(post => [post.id, post])).values());

  return (
    <div className="posts">
      {uniquePosts.filter(post => post.showOnHomePage).map(post => (
        <div key={post.id} className="post-container">
          <Post {...post} />
        </div>
      ))}
    </div>
  );
};

export default HomePage;
