// InfoPage.js

import React, { useEffect } from 'react';
import './InfoPage.css';

const InfoPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);

    return (
        <div className="info-container">
            <section className="welcome-section">
                <h1>Welcome to HyroVault</h1>
                <p>HyroVault is a platform where users can post items they're interested in buying, along with the price they're offering to pay and acceptable condition(s). If other users have what you're looking for, they can message you. If you have what other users are looking for, you can message them. Message each other, share pictures, arrange transactions. It's up to you! Somebody has what we're looking for.</p>
            </section>

            <section className="terms-of-use">
                <h2>Terms of Use</h2>
                <p>HyroVault, a service provided by Hyroglf, LLC, is designed to facilitate a unique marketplace experience. By accessing or interacting with our servers, services, website, or mobile application (“App”), you agree to these Terms of Use ("TOU"). Your use of HyroVault signifies your acceptance of these TOU. Please note that HyroVault is a private platform owned by Hyroglf, LLC. If you represent a business using HyroVault, you confirm your authority to bind that business to these TOU.</p>
                <p>Hyroglf, LLC and HyroVault are not responsible for any aspect of these transactions, including but not limited to payment processing, delivery, or quality of goods. It is important to note that all financial transactions or agreements resulting from interactions on HyroVault occur independently of the platform.</p>
                
                <h3>License</h3>
                <p>Hyroglf, LLC grants you a limited, revocable, non-exclusive, non-assignable license to use HyroVault in compliance with these TOU. Unauthorized use is not permitted. You are prohibited from misusing any content from HyroVault, except for content you personally create.</p>
                
                <h3>Usage Policy</h3>
                <p>You agree to use HyroVault responsibly and lawfully, adhering to the prohibited content guidelines outlined below. You must respect all moderation efforts and the privacy and rights of other users.</p>
                
                <h3>Prohibited Content</h3>
                <p>The following items and services are strictly prohibited from being listed on HyroVault:</p>
                <ul>
                    <li>Adult Products: No promotion of adult products.</li>
                    <li>Alcohol: No promotion of buying or selling alcohol.</li>
                    <li>Body Parts and Fluids: No buying or selling of human body parts or fluids.</li>
                    <li>Discrimination: No content that discriminates based on personal characteristics.</li>
                    <li>Documents, Currency, and Financial Instruments: No buying or selling of real or fake documents, currency, or financial instruments.</li>
                    <li>Gambling: No promotion of online gambling.</li>
                    <li>Hazardous Goods: No buying or selling of hazardous materials.</li>
                    <li>Human Exploitation: No promotion of human trafficking or sexual services.</li>
                    <li>Ingestible Supplements: No buying or selling of ingestible supplements.</li>
                    <li>Jobs: No job opportunities listings.</li>
                    <li>Land, Animals, and Animal Products: Restrictions on buying or selling certain animal products and land.</li>
                    <li>Medical and Healthcare Products: Certain restrictions apply.</li>
                    <li>Misleading, Violent, or Hateful: No misleading, violent, or hateful products.</li>
                    <li>Prescription Products, Drugs, and Paraphernalia: No promotion of these items.</li>
                    <li>Recalled Products: No buying or selling of recalled products.</li>
                    <li>Services: Certain services are not allowed.</li>
                    <li>Sexually Positioned Products: Specific restrictions on how products are presented.</li>
                    <li>Stolen Goods: No promotion of stolen items.</li>
                    <li>Subscriptions and Digital Products: No buying or selling of certain digital products.</li>
                    <li>Third-Party Infringement: No infringement of intellectual property rights.</li>
                    <li>Tobacco Products and Paraphernalia: No promotion of these items.</li>
                    <li>Used Cosmetics: Restrictions apply.</li>
                    <li>Vehicle Parts and Accessories: Certain restrictions apply.</li>
                    <li>Weapons, Ammunition, and Explosives: No promotion of these items.</li>
                </ul>
            </section>

            <section className="disclaimer-section">
                <h2>Disclaimer & Liability</h2>
                <p>HyroVault, provided "AS IS" by Hyroglf, LLC, comes without warranties of any kind. Hyroglf, LLC is not liable for any damages or losses resulting from your use of HyroVault or from transactions arising from its use.</p>
            </section>

            <section className="claims-section">
                <h2>Claims & Indemnity</h2>
                <p>Any disputes related to these TOU will be governed by the laws of the jurisdiction where Hyroglf, LLC is located. You agree to indemnify Hyroglf, LLC against any claims related to your use of HyroVault.</p>
            </section>

            <section className="miscellaneous-section">
                <h2>Miscellaneous</h2>
                <p>These TOU constitute the entire agreement between you and Hyroglf, LLC regarding HyroVault. Changes to these TOU will be effective upon posting, and your continued use of HyroVault signifies acceptance of these changes.</p>
            </section>
        </div>
    );
}

export default InfoPage;
