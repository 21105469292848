// PostForm.js

import React, { useState, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import heic2any from 'heic2any';
import { IoMdCloudUpload, IoMdImages } from 'react-icons/io';
import { useNavigate, Link } from 'react-router-dom'; // Ensure Link is imported
import './PostForm.css';
import { saveDataToDB, uploadImageToStorage } from '../utils/dbUtilities';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../utils/firebaseConfig';
import { useAuth } from '../contexts/AuthContext';
import PostsContext from '../contexts/PostsContext';
import CloseButton from './CloseButton';
import { useLocation } from 'react-router-dom';

function MyDropzone({ onImageUpload, setIsImageProcessing, setProgress, setUploadError }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [localError, setLocalError] = useState('');

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const invalidFileTypes = ['video/mp4', 'audio/wav', 'image/gif', 'video/x-ms-wmv'];

    const checkFileValidity = (file) => {
        if (invalidFileTypes.includes(file.type)) {
            const errorMsg = 'Invalid file type. MP4, WAV, GIF, and WMV files are not allowed.';
            setUploadError(errorMsg);
            setLocalError(errorMsg);
            setIsImageProcessing(false);
            setProgress(0);
            return false;
        }

        if (file.size > MAX_FILE_SIZE) {
            const errorMsg = 'File size exceeds 5 MB limit.';
            setUploadError(errorMsg);
            setLocalError(errorMsg);
            setIsImageProcessing(false);
            setProgress(0);
            return false;
        }

        setUploadError('');
        setLocalError('');
        return true;
    };

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);
                canvas.toBlob(blob => {
                    setProgress(70);
                    resolve(blob);
                }, 'image/jpeg', 0.5);
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    };

    const convertImageToJPG = async (file) => {
        return new Promise(async (resolve, reject) => {
            if (file.type === 'image/heic') {
                try {
                    const heic2any = (await import('heic2any')).default;
                    const convertedBlob = await heic2any({
                        blob: file,
                        toType: "image/jpeg",
                        quality: 0.92
                    });
                    setProgress(50);
                    resolve(convertedBlob);
                } catch (error) {
                    console.error("Error converting image:", error);
                    reject(error);
                }
            } else {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    canvas.toBlob(blob => {
                        setProgress(70);
                        resolve(blob);
                    }, 'image/jpeg', 0.92);
                };
                img.onerror = reject;
                img.src = URL.createObjectURL(file);
            }
        });
    };       

    const removeImage = () => {
        setSelectedImage(null);
        setUploadError('');
        onImageUpload(null);
    };

    const onDrop = async (acceptedFiles) => {
        setIsImageProcessing(true);
        setProgress(5);
        const file = acceptedFiles[0];

        if (!checkFileValidity(file)) {
            return;
        }

        try {
            const imageBlob = file.type === 'image/heic' ? await convertImageToJPG(file) : await compressImage(file);
            // Set the image for preview
            setSelectedImage(URL.createObjectURL(imageBlob));
            setProgress(80);
            onImageUpload(imageBlob);
            setProgress(100);
            setIsImageProcessing(false);
        } catch (error) {
            console.error("Error processing image", error);
            setIsImageProcessing(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop
    });

    return (
        <div className="image-upload-section">
            {localError && <p className="error-message">{localError}</p>}
            {selectedImage ? (
                <div className="image-preview-container">
                    <img src={selectedImage} alt="Uploaded preview" className="uploaded-image-preview" />
                    <span className="remove-image-icon" onClick={() => removeImage()}>X</span>
                </div>
            ) : (
                <div {...getRootProps()} className="drag-drop-zone">
                    <input {...getInputProps()} />
                    <IoMdCloudUpload size={50} className="upload-icon" />
                    <p>Drag & Drop or Click Here to Upload Image (5 MB Size Limit)</p>
                    <IoMdImages size={50} className="images-icon" />
                </div>
            )}
        </div>
    );
}

function PostForm() {
    const [uploadedImageBlob, setUploadedImageBlob] = useState(null);
    const [isImageProcessing, setIsImageProcessing] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadError, setUploadError] = useState('');
    const [nickname, setNickname] = useState('');
    const [price, setPrice] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [nicknameError, setNicknameError] = useState(false);
    const [priceError, setPriceError] = useState(false);
    const [conditionError, setConditionError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { addNewPost } = useContext(PostsContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const location = useLocation(); 
    const suggestionText = location.state?.suggestionText || '';

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.state?.suggestionText) {
            setNickname(location.state.suggestionText);
        }
    }, [location.state]);    

    const handleImageUpload = (imageBlob) => {
        setUploadedImageBlob(imageBlob);
        setIsImageProcessing(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        if (!currentUser || !currentUser.uid) {
            console.error("User is not authenticated or user data is not available");
            navigate('/login');
            return;
        }
    
        const form = event.target;
        const nickname = form['nickname'].value.trim();
        const price = form['price'].value;
        const city = form['city'].value.trim();
        const state = form['state'].value.trim();
        const country = form['country'].value.trim();
    
        const isPriceValid = !isNaN(price) && price !== '';
        const isCityValid = city !== '';
        const isCountryValid = country !== '';
    
        setNicknameError(!nickname);
        setPriceError(!isPriceValid);
        setCityError(!isCityValid);
        setCountryError(!isCountryValid);
    
        const isConditionChecked = Array.from(form['condition']).some(checkbox => checkbox.checked);
        const isLocationChecked = Array.from(form['location']).some(checkbox => checkbox.checked);
        setConditionError(!isConditionChecked);
        setLocationError(!isLocationChecked);
    
        if (!nickname || !isPriceValid || !isConditionChecked || !isLocationChecked || !isCityValid || !isCountryValid || isImageProcessing) {
            console.error("All fields except state and image upload are required and valid, and image must be finished processing.");
            setIsSubmitting(false);
            return;
        }
    
        const conditions = [];
        form['condition'].forEach((checkbox) => {
            if (checkbox.checked) conditions.push(checkbox.value);
        });
    
        const locations = [];
        form['location'].forEach((checkbox) => {
            if (checkbox.checked) locations.push(checkbox.value);
        });
    
        let cleanedPrice = parseFloat(price);
    
        try {
            let imageDownloadUrl = null;
            if (uploadedImageBlob) {
                imageDownloadUrl = await uploadImageToStorage(uploadedImageBlob, `images/${currentUser.uid}_${new Date().toISOString()}`);
            }
    
            const newPostData = {
                userId: currentUser.uid,
                title: nickname,
                price: cleanedPrice,
                conditions: conditions,
                checkboxLocation: locations.join(', '),
                city: city,
                state: state,
                country: country,
                author: currentUser.displayName || "Anonymous",
                upvotes: 0,
                timestamp: serverTimestamp(), // Continue using serverTimestamp for consistency
                imageUrl: imageDownloadUrl,
                showOnHomePage: false
            };
    
            const docRef = await addDoc(collection(db, 'posts'), newPostData);
    
            // Optimistically add the new post to the context/state for immediate display
            const optimisticPost = { ...newPostData, id: docRef.id, timestamp: new Date() }; // Client-side timestamp
            addNewPost(optimisticPost);
    
            setUploadedImageBlob(null);
            navigate(`/posts/${docRef.id}`);
        } catch (error) {
            console.error("Error saving post:", error);
            setIsSubmitting(false);
        }
    };
    
    const handleClose = () => {
        navigate(-1); // Navigate back to the previous page
    };    

    return (
        <div className="form-container">
            <CloseButton onClick={handleClose} />
            <div className="form-header" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '2px solid #333', // Ensure this applies across the entire header
                paddingBottom: '15px'
            }}>
                <h2 className="form-title" style={{
                    marginBottom: '0',
                    color: '#ffffff',
                    flex: 1, // Allows the title to take up necessary space
                    border: 'none' // Ensure no border is applied here
                }}>New Post</h2>
            </div>
            <Link to="/suggestions" style={{
                display: 'block',  // Changed to 'block' to place the link on a new line
                textAlign: 'center', // Center align the link text
                color: '#007bff', // Standard link color
                fontSize: '16px',
                fontWeight: 'bold',
                marginTop: '10px', // Space above the link
                marginBottom: '20px' // Space below the link before form starts
            }}>
                Generate Post Suggestions (AI)
            </Link>
            <form className="post-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>What are you looking for?</label>
                    <input type="text" name="nickname" className="form-input" placeholder="e.g., 1988 Ralph Lauren Men's Rugby Polo" value={nickname} onChange={e => setNickname(e.target.value)} />
                    {nicknameError && <p className="error-message">Please enter what you are looking for.</p>}
                </div>
                <div className="form-group">
                    <label>How much are you offering to pay? (including shipping)</label>
                    <input type="number" name="price" className="form-input" placeholder="e.g., $50" value={price} onChange={e => setPrice(e.target.value)} />
                    {priceError && <p className="error-message">Please enter a valid price.</p>}
                </div>
                <div className="form-group">
                    <label>Acceptable condition(s)?</label>
                    <div className="form-options">
                        <div>
                            <input type="checkbox" id="fair" name="condition" value="Fair" />
                            <label htmlFor="fair">Fair</label>
                        </div>
                        <div>
                            <input type="checkbox" id="good" name="condition" value="Good" />
                            <label htmlFor="good">Good</label>
                        </div>
                        <div>
                            <input type="checkbox" id="excellent" name="condition" value="Excellent" />
                            <label htmlFor="excellent">Excellent</label>
                        </div>
                        <div>
                            <input type="checkbox" id="new" name="condition" value="Brand New" />
                            <label htmlFor="new">Brand New</label>
                        </div>
                    </div>
                    {conditionError && <p className="error-message">Please select at least one condition.</p>}
                </div>
                <div className="form-group">
                    <label>Where do you live?</label>
                    <div className="form-options">
                        <div>
                            <input type="radio" id="northamerica" name="location" value="North America" />
                            <label htmlFor="northamerica">North America</label>
                        </div>
                        <div>
                            <input type="radio" id="europe" name="location" value="Europe" />
                            <label htmlFor="europe">Europe</label>
                        </div>
                        <div>
                            <input type="radio" id="africa" name="location" value="Africa" />
                            <label htmlFor="africa">Africa</label>
                        </div>
                        <div>
                            <input type="radio" id="middleeast" name="location" value="Middle East" />
                            <label htmlFor="middleeast">Middle East</label>
                        </div>
                        <div>
                            <input type="radio" id="asia" name="location" value="Asia" />
                            <label htmlFor="asia">Asia</label>
                        </div>
                        <div>
                            <input type="radio" id="centralamerica" name="location" value="Central America" />
                            <label htmlFor="centralamerica">Central America</label>
                        </div>
                        <div>
                            <input type="radio" id="southamerica" name="location" value="South America" />
                            <label htmlFor="southamerica">South America</label>
                        </div>
                        <div>
                            <input type="radio" id="australia" name="location" value="Australia" />
                            <label htmlFor="australia">Australia</label>
                        </div>
                    </div>
                    {locationError && <p className="error-message">Please select at least one location.</p>}
                </div>
                <div className="form-group">
                    <label>City</label>
                    <input type="text" name="city" className="form-input" value={city} onChange={e => setCity(e.target.value)} />
                    {cityError && <p className="error-message">Please enter your city.</p>}
                </div>
                <div className="form-group">
                    <label>State (optional)</label>
                    <input type="text" name="state" className="form-input" value={state} onChange={e => setState(e.target.value)} />
                </div>
                <div className="form-group">
                    <label>Country</label>
                    <input type="text" name="country" className="form-input" value={country} onChange={e => setCountry(e.target.value)} />
                    {countryError && <p className="error-message">Please enter your country.</p>}
                </div>
                <MyDropzone
                    onImageUpload={handleImageUpload}
                    setIsImageProcessing={setIsImageProcessing}
                    setProgress={setProgress}
                    setUploadError={setUploadError}
                />
                {uploadError && <p className="error-message">{uploadError}</p>}
                {isImageProcessing && (
                    <div className="progress-bar-container">
                        <progress value={progress} max="100"></progress>
                    </div>
                )}
                <button type="submit" className="form-button" disabled={isImageProcessing || isSubmitting}>
                    {isSubmitting ? 'Posting...' : 'Post'}
                </button>
            </form>
        </div>
    );          
}

export default PostForm;