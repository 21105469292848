// AdminDashboard.js

import React, { useState, useEffect, Suspense } from 'react';
import ManageUsers from './ManageUsers';
import ViewReports from './ViewReports';
import { getAllContactSubmissions, updatePostVerificationPassword } from '../utils/dbUtilities';
import './AdminDashboard.css';

// Dynamic imports for components
const ManagePosts = React.lazy(() => import('./ManagePosts'));

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('posts');
    const [contactSubmissions, setContactSubmissions] = useState([]);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    useEffect(() => {
      getAllContactSubmissions().then(submissions => {
          // Assuming each submission has a 'timestamp' field
          const sortedSubmissions = submissions
              .map(submission => ({
                  ...submission,
                  timestamp: submission.timestamp?.toDate ? submission.timestamp.toDate() : new Date(submission.timestamp)
              }))
              .sort((a, b) => b.timestamp - a.timestamp);
          setContactSubmissions(sortedSubmissions);
      }).catch(error => {
          console.error('Failed to load contact submissions:', error);
      });
  }, []);  

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmNewPasswordChange = (event) => {
        setConfirmNewPassword(event.target.value);
    };

    const updatePassword = () => {
        if (newPassword === confirmNewPassword) {
            updatePostVerificationPassword(newPassword)
                .then(() => {
                    console.log('New password set: ', newPassword);
                    setNewPassword('');
                    setConfirmNewPassword('');
                    alert('Password updated successfully');
                })
                .catch(error => {
                    console.error('Error updating password:', error);
                    alert('Error updating password. Please try again.');
                });
        } else {
            alert('Passwords do not match. Please try again.');
        }
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const getTabClass = (tabName) => {
        return `admin-dashboard-tab-button ${activeTab === tabName ? 'active' : ''}`;
    };

    const renderContactSubmission = (submission) => {
        return (
            <div key={submission.id} className="report-item">
                <p>From: {submission.name} ({submission.email}){submission.username ? ` (${submission.username})` : ''}</p>
                <p>Message: {submission.message}</p>
                <p>Sent: {submission.timestamp ? submission.timestamp.toLocaleString() : "Date not available"}</p>
            </div>
        );
    };

    return (
        <div className="admin-dashboard-container">
            <div className="admin-dashboard-header">
                <h1>Admin Dashboard</h1>
            </div>
            <ul className="admin-dashboard-tabs">
                <li className={getTabClass('posts')} onClick={() => handleTabClick('posts')}>Manage Posts</li>
                <li className={getTabClass('users')} onClick={() => handleTabClick('users')}>Manage Users</li>
                <li className={getTabClass('reports')} onClick={() => handleTabClick('reports')}>View Reports</li>
                <li className={getTabClass('contact')} onClick={() => handleTabClick('contact')}>Contact Submissions</li>
                <li className={getTabClass('password')} onClick={() => handleTabClick('password')}>Post Verification Password</li>
            </ul>
            <div className="admin-dashboard-content">
                <Suspense fallback={<div>Loading...</div>}>
                    {activeTab === 'posts' && <ManagePosts />}
                    {activeTab === 'users' && <ManageUsers />}
                    {activeTab === 'reports' && <ViewReports />}
                    {activeTab === 'contact' && (
                        <div>
                            <h2>Contact Form Submissions</h2>
                            <div className="reports-list">
                                {contactSubmissions.map(renderContactSubmission)}
                            </div>
                        </div>
                    )}
                    {activeTab === 'password' && (
                        <div>
                            <h2>Change Post Verification Password</h2>
                            <div>
                                <label>
                                    New Password:
                                    <input
                                        type="password"
                                        value={newPassword}
                                        onChange={handleNewPasswordChange}
                                        placeholder="Enter new password"
                                    />
                                </label>
                            </div>
                            <div>
                                <label>
                                    Confirm New Password:
                                    <input
                                        type="password"
                                        value={confirmNewPassword}
                                        onChange={handleConfirmNewPasswordChange}
                                        placeholder="Confirm new password"
                                    />
                                </label>
                            </div>
                            <button onClick={updatePassword}>Update Password</button>
                        </div>
                    )}
                </Suspense>
            </div>
        </div>
    );
}

export default AdminDashboard;




