// Post.js

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardActions, CardContent, CardMedia, Typography, IconButton, Tooltip } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FaEnvelope } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { ReportsContext } from '../contexts/ReportsContext';
import PostsContext from '../contexts/PostsContext';
import { timeAgo } from '../utils/timeFormatter';
import ReportForm from './ReportForm';
import './Post.css';
import ChatWindow from './ChatWindow';
import { formatPrice } from '../utils/priceFormatter';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, getDocs } from 'firebase/firestore';

const Post = ({
    id,
    title,
    author,
    price,
    conditions,
    checkboxLocation,
    city,
    state,
    country,
    location,
    image,
    timestamp
}) => {
    const navigate = useNavigate();
    const { currentUser, isLoggedIn } = useAuth();
    const { reportPost } = useContext(ReportsContext);
    const { deletePostById } = useContext(PostsContext);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const [showReportForm, setShowReportForm] = useState(false);
    const [isAuthor, setIsAuthor] = useState(currentUser && currentUser.displayName === author);
    const [copySuccess, setCopySuccess] = useState('');
    const [showChatWindow, setShowChatWindow] = useState(false);
    const { posts } = useContext(PostsContext);
    const postDetails = posts.find(p => p.id === id);

    useEffect(() => {
        // Always fetch likes count regardless of user being logged in
        const likesQuery = query(collection(db, 'likes'), where('postId', '==', id));
        const unsubscribe = onSnapshot(likesQuery, (snapshot) => {
            const likes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLikeCount(likes.length);
            // Check if the current user has liked the post only if a user is logged in
            if (currentUser) {
                setIsLiked(likes.some(like => like.userId === currentUser.uid));
                setIsAuthor(currentUser.displayName === author);
            }
        });

        return () => unsubscribe(); // Cleanup
    }, [id, currentUser, author]);

    const handleLike = async (event) => {
        event.stopPropagation(); // Prevent event from propagating to parent elements

        if (!isLoggedIn || !currentUser || !id) { // Ensure `isLoggedIn`, `currentUser`, and `id` are defined
            navigate('/login');
            return;
        }

        if (isLiked) {
            // Find the like document ID
            const likeQuery = query(collection(db, 'likes'), where('postId', '==', id), where('userId', '==', currentUser.uid));
            const snapshot = await getDocs(likeQuery);
            const likeDoc = snapshot.docs[0];
            if (likeDoc) {
                await deleteDoc(likeDoc.ref); // Remove like from Firestore
            }
        } else {
            await addDoc(collection(db, 'likes'), {
                postId: id,
                userId: currentUser.uid,
                timestamp: new Date()
            });
        }
    };

    const handleUsernameClick = (e) => {
        e.stopPropagation();
        navigate(`/usersposts/${author}`);
    };

    // Modify this function to navigate to the PostPage.js
    const navigateToPostPage = () => {
        navigate(`/posts/${id}`);
    };

    // Updated sharePost function with event.stopPropagation()
    const sharePost = (event) => {
        event.stopPropagation(); // Prevent event from propagating to parent elements

        const url = `${window.location.origin}/posts/${id}`;
        navigator.clipboard.writeText(url).then(() => {
            setCopySuccess('Link copied!');
            setTimeout(() => setCopySuccess(''), 3000);
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const toggleReportForm = (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setShowReportForm(!showReportForm);
    };

    const handleRemove = async (e) => {
        e.stopPropagation();
        if (window.confirm("Are you sure you want to delete this post?")) {
            console.log(`Attempting to delete post with document ID: ${id}`);
            await deletePostById(id); // Use the Firestore document ID directly from the `id` prop
            navigate('/');
        }
    };

    const handleEdit = (e) => {
        e.stopPropagation();
        navigate(`/editpost/${id}`);
    };

    // Add a new state for the chat window width
    const [chatWindowWidth, setChatWindowWidth] = useState('var(--chat-window-width,  90%)');

    // Modify the openChatWindow function
    const openChatWindow = (e) => {
        e.stopPropagation();
        if (!isLoggedIn) {
            navigate('/login');
            return;
        }
        setChatWindowWidth('100%'); // Set the width to  100% when "I Got It!" is clicked
        setShowChatWindow(true);
    };

    if (!postDetails) {
        return null; // Render nothing for deleted or missing posts
    }

    if (postDetails.isReported) {
        return (
            <Card className="post" sx={{ marginBottom: 2 }}>
                <CardContent>
                    <Typography variant="h6">Reported Post Under Review</Typography>
                </CardContent>
            </Card>
        );
    }

    // Convert timestamp to Date object
    const postDate = timestamp ? (timestamp instanceof Date ? timestamp : timestamp.toDate()) : null;

    return (
        <Card className="post" sx={{ marginBottom: 2, position: 'relative' }}>
            <CardMedia
                component="img"
                image={postDetails.imageUrl || "https://via.placeholder.com/720x720"}
                alt={title}
            />
            <CardContent>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'center', width: '100%', cursor: 'pointer' }} onClick={() => navigate(`/posts/${id}`)}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', width: '100%' }} onClick={handleUsernameClick}>
                        Posted {timeAgo(postDate)} by <span style={{ fontWeight: 'bold' }}>{author}</span>
                    </Typography>
                    <Typography variant="body1" color="text.primary" style={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                        I'll Buy This From You For {formatPrice(price)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', width: '100%' }}>
                        Acceptable Condition(s): {conditions.join(', ')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', width: '100%' }}>
                        I live in {city}{state ? `, ${state}` : ''}, {country} ({checkboxLocation})
                    </Typography>
                    <Tooltip title="Share" style={{ alignSelf: 'center' }}>
                        <IconButton onClick={sharePost}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </CardContent>
            <CardActions disableSpacing style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px' }}>
                <div>
                    <Tooltip title={isLiked ? 'Unlike' : 'Like'}>
                        <IconButton onClick={handleLike} sx={{ color: isLiked ? 'red' : 'inherit' }}>
                            {isLiked ? <Favorite /> : <FavoriteBorder />}
                        </IconButton>
                    </Tooltip>
                    <span className="votes-count">{likeCount}</span>
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    {isAuthor && (
                        <>
                            <Tooltip title="Edit">
                                <IconButton onClick={handleEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <IconButton onClick={handleRemove}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title="Report">
                        <IconButton onClick={toggleReportForm}>
                            <OutlinedFlagIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Send Message">
                        <div onClick={openChatWindow} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <IconButton style={{ color: 'inherit' }}>
                                <FaEnvelope size={22} style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                            </IconButton>
                            <span>I Got It!</span>
                        </div>
                    </Tooltip>
                </div>
            </CardActions>
            {showReportForm && (
                <div onClick={(e) => e.stopPropagation()}>
                    <ReportForm postId={id} submitReport={reportPost} closeModal={() => setShowReportForm(false)} />
                </div>
            )}
            {copySuccess && <div className="copy-success">{copySuccess}</div>}
            {showChatWindow && (
                <ChatWindow
                    initialRecipient={author}
                    onClose={() => setShowChatWindow(false)}
                    defaultMessage="I've got what you're looking for!"
                    width={chatWindowWidth}
                    showChatWindow={showChatWindow}
                    isStandalone={false}
                />
            )}
        </Card>
    );
};

export default Post;
