// ChatComponent.js

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, TextField, IconButton, Avatar, Box, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import './ChatComponent.css';
import { useAuth } from '../contexts/AuthContext';
import { useMessages } from '../contexts/MessagesContext';
import { useBlock } from '../contexts/BlockContext';
import { generateConversationId, sendMessageToDb, getMessagesByConversationId, getUserByUsername } from '../utils/dbUtilities';
import { format } from 'timeago.js';
import heic2any from 'heic2any';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';

const ChatComponent = () => {
  const { currentUser: authUser } = useAuth();
  const { markAllMessagesAsRead, fetchMessages } = useMessages();
  const { username: chatPartnerUsername } = useParams();
  const { blockedUsers, blockUser, unblockUser } = useBlock();
  const [currentUserBlockedChatPartner, setCurrentUserBlockedChatPartner] = useState(false);
  const [chatPartnerBlocked, setIsChatPartnerBlocked] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [imageToSend, setImageToSend] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const messageListRef = useRef(null);
  const bottomRef = useRef(null);
  const fileInputRef = useRef(null);

  // Helper function to update scroll position to the top
  const updateScroll = () => {
    if (window.innerWidth < 768) {
      window.scrollTo(0, 0);
    }
  };

  // Function to scroll to the bottom of the message area
  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }, 100); // Increased timeout to 100ms
  };

  // useLayoutEffect hook to scroll to the top on component mount for mobile devices
  useLayoutEffect(() => {
    updateScroll();
    // After ensuring we're at the top, we can subscribe to messages
    const unsubscribe = fetchAndUpdateMessages();

    return () => {
      console.log('Unsubscribing from Firestore updates in ChatComponent');
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [authUser, chatPartnerUsername, blockedUsers]);

  useEffect(() => {
    const checkBlockStatus = async () => {
      if (authUser && chatPartnerUsername) {
        try {
          const blockedByCurrentUser = !!blockedUsers[chatPartnerUsername];
          setCurrentUserBlockedChatPartner(blockedByCurrentUser);

          const chatPartnerUserDoc = await getUserByUsername(chatPartnerUsername);
          const chatPartnerBlockedUsers = chatPartnerUserDoc?.blockedUsers || {};
          const blockedByChatPartner = !!chatPartnerBlockedUsers[authUser.displayName];

          setIsChatPartnerBlocked(blockedByChatPartner);
        } catch (error) {
          console.error("Error checking block status:", error);
          setCurrentUserBlockedChatPartner(false);
          setIsChatPartnerBlocked(false);
        }
      }
    };

    checkBlockStatus();

    const unsubscribe = fetchAndUpdateMessages();

    return () => {
      console.log('Unsubscribing from Firestore updates in ChatComponent');
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [authUser, chatPartnerUsername, blockedUsers]);

  const handleBlock = async () => {
    console.log("Blocking user:", chatPartnerUsername);
    await blockUser(chatPartnerUsername);
    setCurrentUserBlockedChatPartner(true);
    fetchAndUpdateMessages();
  };

  const handleUnblock = async () => {
    console.log("Unblocking user:", chatPartnerUsername);
    await unblockUser(chatPartnerUsername);
    setCurrentUserBlockedChatPartner(false);
    fetchAndUpdateMessages();
  };

  const fetchAndUpdateMessages = () => {
    if (!authUser || !chatPartnerUsername) {
      console.error('Missing user information or chatPartnerUsername, cannot fetch messages');
      setLoading(false);
      return;
    }

    const conversationId = generateConversationId(authUser.displayName, chatPartnerUsername);
    console.log("Fetching messages for conversationId:", conversationId);

    const q = query(collection(db, 'messages'), where('conversationId', '==', conversationId), orderBy('timestamp', 'asc'));

    return onSnapshot(q, querySnapshot => {
      console.log("Received new messages:", querySnapshot.docs);
      const newMessages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Updating messages with new messages:', newMessages);
      setMessages(newMessages);
      console.log('Updated messages:', newMessages);
      setLoading(false);
      scrollToBottom();
      if (newMessages.some(msg => !msg.read && msg.sender !== authUser.displayName)) {
        markAllMessagesAsRead(conversationId);
        fetchMessages(); // Ensure Navbar is updated
        localStorage.setItem('messagesRead', JSON.stringify({ user: chatPartnerUsername, timestamp: new Date() }));
      }
    }, error => {
      console.error('Error fetching messages:', error);
      setError('Failed to load messages');
      setLoading(false);
    });
  };

  useEffect(() => {
    console.log('Starting Firestore updates in ChatComponent');
    if (authUser && chatPartnerUsername) {
      const unsubscribe = fetchAndUpdateMessages();
      return () => {
        console.log('Unsubscribing from Firestore updates in ChatComponent');
        unsubscribe();
        // Mark messages as read when the component unmounts
        if (messages.some(msg => !msg.read && msg.sender !== authUser.displayName)) {
          markAllMessagesAsRead(generateConversationId(authUser.displayName, chatPartnerUsername));
          fetchMessages();
        }
      };
    }
  }, [authUser, chatPartnerUsername, messages]);

  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const convertImageToJPG = async (file) => {
    return new Promise(async (resolve, reject) => {
      if (file.type === 'image/heic') {
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.92
          });
          resolve(convertedBlob);
        } catch (error) {
          console.error("Error converting image:", error);
          reject(error);
        }
      } else {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob(blob => {
            resolve(blob);
          }, 'image/jpeg', 0.92);
        };
        img.onerror = (error) => {
          console.error("Error loading image:", error);
          reject(error);
        };
        img.src = URL.createObjectURL(file);
      }
    });
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      alert("Image size should not exceed 5 MB.");
      return;
    }
    try {
      const convertedImageBlob = await convertImageToJPG(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageToSend(reader.result);
      };
      reader.readAsDataURL(convertedImageBlob);
    } catch (error) {
      console.error("Error converting image:", error);
    }
  };

  const handleSend = async () => {
    console.log('Attempting to send message:', newMessage);
    if (newMessage.trim() === '' && !imageToSend) {
      console.log('Empty message, not sending.');
      return;
    }

    if (currentUserBlockedChatPartner || chatPartnerBlocked) {
      console.log('Cannot send message, user is blocked.');
      alert("Cannot send message to blocked user");
      return;
    }

    const messageData = {
      text: newMessage.trim(),
      image: imageToSend,
      timestamp: new Date().toISOString(),
      read: false,
    };

    console.log(`Sending message: ${JSON.stringify(messageData)}`); // Log the message being sent

    await sendMessageToDb(authUser.displayName, chatPartnerUsername, messageData);
    console.log('Message sent:', messageData);
    setNewMessage('');
    setImageToSend(null);
    scrollToBottom();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  if (loading) {
    return <div>Loading messages...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const shouldShowTimestamp = (index) => {
    if (index === 0 || !messages[index - 1] || !messages[index].timestamp) return true;
    const currentMsgTimestamp = new Date(messages[index].timestamp);
    const prevMsgTimestamp = new Date(messages[index - 1].timestamp);
    return isNaN(currentMsgTimestamp) || isNaN(prevMsgTimestamp) || currentMsgTimestamp - prevMsgTimestamp > 5 * 60 * 1000;
  };

  const chatMessages = messages.length > 0 && messages.map((msg, index) => {
    console.log(`Message object for index ${index}:`, msg); // Added this line

    return (
      <React.Fragment key={msg.id || index}>
        {shouldShowTimestamp(index) && msg.timestamp && !isNaN(new Date(msg.timestamp)) && (
          <div className="message-timestamp">
            {format(new Date(msg.timestamp))}
          </div>
        )}
        <div className={`message ${msg.sender === authUser?.displayName ? 'outgoing' : 'incoming'}`}
             ref={index === messages.length - 1 ? bottomRef : null}>
          {msg.sender !== authUser?.displayName && (
            <Avatar alt={msg.sender} src={msg.avatar || 'default-avatar-url'} className="avatar" />
          )}
          {msg.text && (
            <Box className={`message-bubble ${msg.sender === authUser?.displayName ? 'outgoing-text' : ''}`}>
              <Typography variant="body2" className={`${msg.sender === authUser?.displayName ? 'outgoing-text' : ''}`}>
                {msg.text}
              </Typography>
            </Box>
          )}
          {msg.image && (
            <img src={msg.image} alt="Sent content"
                 style={{ maxWidth: '100px', maxHeight: '100px', cursor: 'pointer' }}
                 onClick={() => openImageModal(msg.image)} />
          )}
        </div>
      </React.Fragment>
    );
  });

  const blockedMessageStyle = {
    backgroundColor: '#ffdddd',
    color: '#d8000c',
    padding: '10px',
    textAlign: 'center',
    border: '1px solid #d8000c',
    margin: '10px',
    borderRadius: '5px',
  };

  return (
    <div className="chat-component-container">
      <div className="chat-header">
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Typography variant="h6" component="span">
            Chat with: {chatPartnerUsername}
          </Typography>
          <Box position="absolute" right={16}>
            {!chatPartnerBlocked && (
              <Button
                variant="contained"
                color={currentUserBlockedChatPartner ? "secondary" : "primary"}
                onClick={currentUserBlockedChatPartner ? handleUnblock : handleBlock}
                className={currentUserBlockedChatPartner ? "unblock-button" : "block-button"}>
                {currentUserBlockedChatPartner ? 'Unblock' : 'Block'}
              </Button>
            )}
          </Box>
        </Box>
      </div>
      <div className="chat-container" ref={messageListRef}>
        {chatMessages}
        <div ref={bottomRef} />
      </div>
      {currentUserBlockedChatPartner && (
        <div style={blockedMessageStyle}>
          You have blocked this user. Unblock to send messages.
        </div>
      )}
      {chatPartnerBlocked && (
        <div style={blockedMessageStyle}>
          You've Been Blocked
        </div>
      )}
      {!currentUserBlockedChatPartner && !chatPartnerBlocked && (
        <div className="chat-input-area">
          <IconButton onClick={handleImageIconClick} color="primary" className="upload-button">
            <InsertPhotoIcon />
          </IconButton>
          <input
            type="file"
            accept="image/*,image/heic"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={onFileChange}
          />
          {imageToSend && (
            <div className="image-preview">
              <img src={imageToSend} alt="Preview" />
              <Button onClick={() => setImageToSend(null)} style={{ marginTop: '10px' }}>Remove</Button>
            </div>
          )}
          {!imageToSend && (
            <TextField
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              fullWidth
              variant="outlined"
              size="small"
              className="chat-input-field"
            />
          )}
          <IconButton onClick={handleSend} color="primary" className="send-button">
            <SendIcon />
          </IconButton>
        </div>
      )}
      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <img src={selectedImage} alt="Enlarged content" />
            <button className="modal-close" onClick={closeModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatComponent;
