// MyPostsPage.js

import React, { useState, useEffect } from 'react';
import Post from './Post';
import { FaUser } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { deletePostFromDB, getPostsByUserId } from '../utils/dbUtilities';
import './MyPostsPage.css';

function MyPostsPage() {
  const [myPosts, setMyPosts] = useState([]);
  const { currentUser } = useAuth();
  const [topMargin, setTopMargin] = useState('40px');

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts

    async function fetchMyPosts() {
      if (currentUser) {
        try {
          const userPosts = await getPostsByUserId(currentUser.uid);
          const sortedUserPosts = userPosts
            .map(post => ({
              ...post,
              timestamp: post.timestamp?.toDate ? post.timestamp.toDate() : new Date(post.timestamp)
            }))
            .sort((a, b) => b.timestamp - a.timestamp);
          setMyPosts(sortedUserPosts);
        } catch (error) {
          console.error("Error fetching user's posts:", error);
        }
      }
    }

    fetchMyPosts();

    // Adjust the top margin based on screen width
    const updateTopMargin = () => {
      if (window.innerWidth === 1264) {
        setTopMargin('100px'); // Set specific top margin for screen width 1264px
      } else if (window.innerWidth <= 768) {
        setTopMargin('160px'); // Increased top margin for iPad Mini and similar screen sizes
      } else {
        setTopMargin('80px'); // Ensure top margin for larger screens
      }
    };

    // Listen for window resize events
    window.addEventListener('resize', updateTopMargin);

    // Set the initial margin based on the current window size
    updateTopMargin();

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', updateTopMargin);
  }, [currentUser]);

  const handlePostDeletion = async (postId) => {
    try {
      await deletePostFromDB(postId);
      setMyPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <div className="my-posts-container" style={{ marginTop: topMargin }}>
      <h1 style={{ textAlign: 'center' }}><FaUser /> My Posts</h1>
      {myPosts.length > 0 ? (
        myPosts.map(post => (
          <Post
            key={post.id}
            id={post.id}
            title={post.title}
            author={post.author}
            upvotes={post.upvotes}
            price={post.price}
            conditions={post.conditions}
            location={post.location}
            city={post.city}
            state={post.state}
            country={post.country}
            checkboxLocation={post.checkboxLocation}
            image={post.image}
            timestamp={post.timestamp}
            onDelete={() => handlePostDeletion(post.id)}
          />
        ))
      ) : (
        <p>You don't have any posts.</p> // Message displayed when there are no posts
      )}
    </div>
  );
}

export default MyPostsPage;