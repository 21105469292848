// theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1877F2', // Matching Meta's blue color
    },
    secondary: {
      main: '#65676B', // Matching Meta's secondary color
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#F0F2F5', // Matching Meta's background color
    },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: 'bold', // Ensuring bold buttons as in Meta
    },
    h5: {
      fontSize: '1.25rem', // Adjust heading sizes to be consistent
      fontWeight: 'bold',
    },
  },
  shape: {
    borderRadius: 8, // Consistent rounded corners like Meta
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 8,
        },
      },
    },
  },
});

export default theme;

