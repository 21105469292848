// dbUtilities.js

import { db, storage } from './firebaseConfig'; // Import your Firebase storage reference along with Firestore
import { collection, doc, setDoc, getDoc, getDocs, query, where, addDoc, deleteDoc, updateDoc, orderBy, limit } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'; // Import Firebase Storage functions
import { onSnapshot } from 'firebase/firestore';

// Function to save data to Firestore
export const saveDataToDB = async (collectionName, data, id = null) => {
  try {
    const docRef = id ? doc(db, collectionName, id) : doc(collection(db, collectionName));
    await setDoc(docRef, data, { merge: true });
    console.log(`Data saved to ${collectionName} successfully`);
    console.log('Saved Data:', data); // Log the data being saved
    return docRef.id;
  } catch (error) {
    console.error("Error writing to Firestore", error);
    throw new Error("Error writing to Firestore");
  }
};

// Function to get all data from a collection in Firestore
export const getAllDataFromDB = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    const dataList = [];
    querySnapshot.forEach((doc) => dataList.push({ id: doc.id, ...doc.data() }));
    return dataList;
  } catch (error) {
    console.error("Error reading from Firestore", error);
    throw new Error("Error reading from Firestore");
  }
};

// Function to get a user by username
export const getUserByUsername = async (username) => {
  if (!username) {
    throw new Error("Invalid or missing username");
  }
  try {
    const q = query(collection(db, 'users'), where('username', '==', username.toLowerCase()));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No matching user found. Username is available.");
      return null; // Username is available
    }
    let user = null;
    querySnapshot.forEach((doc) => {
      // assuming only one user will have this username
      user = { id: doc.id, ...doc.data() };
    });
    console.log(`getUserByUsername: User found for ${username}`, user); // Log user data
    return user;
  } catch (error) {
    console.error("Error reading from Firestore", error);
    throw error; // Re-throwing the error for unexpected issues
  }
};

// Function to like a post
export const likePost = async (userId, postId) => {
  const timestamp = new Date();
  const likeData = { userId, postId, timestamp };
  const docRef = await addDoc(collection(db, 'likes'), likeData);
  return docRef.id;
};

// Function to unlike a post
export const unlikePost = async (likeId) => {
  try {
    await deleteDoc(doc(db, 'likes', likeId));
  } catch (error) {
    console.error("Error unliking post", error);
    throw new Error("Error unliking post");
  }
};

// Function to get likes for a post
export const getLikesForPost = async (postId) => {
  try {
    const q = query(collection(db, 'likes'), where('postId', '==', postId));
    const querySnapshot = await getDocs(q);
    const likes = [];
    querySnapshot.forEach((doc) => {
      likes.push({ id: doc.id, ...doc.data() });
    });
    return likes;
  } catch (error) {
    console.error("Error fetching likes for post", error);
    throw new Error("Error fetching likes for post");
  }
};

// Function to get likes by a user
export const getUserLikes = async (userId) => {
  try {
    const q = query(collection(db, 'likes'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const likes = [];
    querySnapshot.forEach((doc) => {
      likes.push({ id: doc.id, ...doc.data() });
    });
    return likes;
  } catch (error) {
    console.error("Error fetching user likes", error);
    throw new Error("Error fetching user likes");
  }
};

// Function to generate a conversation ID
export const generateConversationId = (user1, user2) => {
  if (typeof user1 !== 'string' || typeof user2 !== 'string') {
    console.error('Invalid arguments for generateConversationId:', user1, user2);
    return null;
  }
  const conversationId = [user1, user2].sort().join('_');
  console.log(`Generated conversation ID: ${conversationId} for users ${user1} and ${user2}`);
  return conversationId;
};

// Function to block a user
export const blockUserInDB = async (blockingUser, blockedUser, blockTimestamp) => {
  try {
    const userRef = doc(db, 'users', blockingUser);
    const userData = (await getDoc(userRef)).data();
    if (!userData) {
      throw new Error("Blocking user not found");
    }
    userData.blockedUsers = { ...userData.blockedUsers, [blockedUser]: blockTimestamp };
    await setDoc(userRef, userData);
  } catch (error) {
    console.error("Error blocking user", error);
    throw new Error("Error blocking user");
  }
};

// Function to unblock a user
export const unblockUserInDB = async (unblockingUser, blockedUser) => {
  try {
    const userRef = doc(db, 'users', unblockingUser);
    const userData = (await getDoc(userRef)).data();
    if (!userData) {
      throw new Error("Unblocking user not found");
    }
    delete userData.blockedUsers[blockedUser];
    await setDoc(userRef, userData);
  } catch (error) {
    console.error("Error unblocking user", error);
    throw new Error("Error unblocking user");
  }
};

// Function to send a message
export const sendMessageToDb = async (senderUsername, receiverUsername, messageData) => {
  console.log('Received message data:', messageData); // Add this line
  const conversationId = generateConversationId(senderUsername, receiverUsername);
  const message = {
    ...messageData,
    sender: senderUsername,
    receiver: receiverUsername,
    conversationId,
    timestamp: messageData.timestamp || new Date().toISOString(), // Ensure timestamp is always set
    read: false,
  };

  console.log("Preparing to send message to Firestore:", {
    sender: senderUsername, 
    receiver: receiverUsername, 
    messageDetails: message
  });

  try {
    const docRef = await addDoc(collection(db, 'messages'), message);
    console.log("Message sent successfully to Firestore. Message ID:", docRef.id);
    return docRef.id; // Return the ID of the newly created document
  } catch (error) {
    console.error("Error sending message to Firestore:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

// Updated to handle initial message inclusion
export const getMessagesByConversationId = (conversationId, updateMessages, onError) => {
  console.log(`Setting up listener for conversationId: ${conversationId}`);
  const q = query(collection(db, 'messages'), where('conversationId', '==', conversationId), orderBy('timestamp', 'asc'));
  
  return onSnapshot(q, querySnapshot => {
    console.log(`Received snapshot for conversationId: ${conversationId}`);
    const messages = [];
    querySnapshot.forEach(doc => {
      console.log(`Message received: ${doc.id}`, doc.data());
      messages.push({ id: doc.id, ...doc.data() });
    });
    updateMessages(messages); // Update state with new messages
  }, (error) => {
    console.error(`Error fetching messages for conversationId: ${conversationId}`, error);
    if (onError) onError(error);
  });
};

// Function to get chat partners
export const getChatPartners = async (currentUsername) => {
  try {
    const sentMessagesQuery = query(collection(db, 'messages'), where('sender', '==', currentUsername));
    const receivedMessagesQuery = query(collection(db, 'messages'), where('receiver', '==', currentUsername));
    
    const [sentMessagesSnapshot, receivedMessagesSnapshot] = await Promise.all([
      getDocs(sentMessagesQuery),
      getDocs(receivedMessagesQuery)
    ]);

    const chatPartners = new Set();
    sentMessagesSnapshot.forEach((doc) => {
      const message = doc.data();
      chatPartners.add(message.receiver);
    });
    receivedMessagesSnapshot.forEach((doc) => {
      const message = doc.data();
      chatPartners.add(message.sender);
    });

    return Array.from(chatPartners);
  } catch (error) {
    console.error("Error fetching chat partners", error);
    throw new Error("Error fetching chat partners");
  }
};

// Function to add a report to the reports collection
export const addReportToDB = async (reportData) => {
  try {
    const reportId = await saveDataToDB('reports', reportData);
    return reportId;
  } catch (error) {
    console.error("Error adding report", error);
    throw new Error("Error adding report");
  }
};

// Function to get all reports from the reports collection
export const getAllReportsFromDB = async () => {
  try {
    const reports = await getAllDataFromDB('reports');
    return reports;
  } catch (error) {
    console.error("Error fetching reports", error);
    throw new Error("Error fetching reports");
  }
};

// Function to get reports by postId
export const getReportsByPostId = async (postId) => {
  try {
    const q = query(collection(db, 'reports'), where('postId', '==', postId));
    const querySnapshot = await getDocs(q);
    const reports = [];
    querySnapshot.forEach((doc) => {
      reports.push({ id: doc.id, ...doc.data() });
    });
    return reports;
  } catch (error) {
    console.error("Error fetching reports for post", error);
    throw new Error("Error fetching reports for post");
  }
};

// Function to report a user
export const reportUser = async (userId, reportDetails) => {
  try {
    const fullReportDetails = {
      ...reportDetails,
      userId, // The ID of the user being reported
      type: 'user', // Adding a type property to distinguish user reports
      timestamp: new Date() // Timestamp of the report
    };
    await saveDataToDB('reports', fullReportDetails);
  } catch (error) {
    console.error("Error adding user report", error);
    throw new Error("Error adding user report");
  }
};

// Function to report a post
export const reportPost = async (reportDetails) => {
  try {
    // Ensure the timestamp is formatted correctly
    const formattedReportDetails = {
      ...reportDetails,
      timestamp: new Date().toISOString(), // Store timestamp in ISO 8601 format
    };
    const reportId = await saveDataToDB('reports', formattedReportDetails);
    console.log("Report successfully saved with ID:", reportId);
    return reportId;
  } catch (error) {
    console.error("Error reporting post", error);
    throw new Error("Error reporting post");
  }
};

// Function to delete a user from the database
export const deleteUserFromDB = async (userId) => {
  try {
    await deleteDoc(doc(db, 'users', userId));
  } catch (error) {
    console.error("Error deleting user from Firestore", error);
    throw new Error("Error deleting user from Firestore");
  }
};

// Function to delete a post from the database
export const deletePostFromDB = async (collectionName, docId) => {
  console.log(`deletePostFromDB: Attempting to delete from collection '${collectionName}' with docId: ${docId}`);
  try {
      const postRef = doc(db, collectionName, docId);
      await deleteDoc(postRef);
      console.log(`deletePostFromDB: Document with docId: ${docId} successfully deleted from Firestore collection '${collectionName}'`);
  } catch (error) {
      console.error(`deletePostFromDB: Error deleting document from collection '${collectionName}' with docId: ${docId}`, error);
      throw new Error(`deletePostFromDB: Error deleting document from Firestore: ${error}`);
  }
};

// Function to update a post in the database with enhanced logging
export const updatePostInDB = async (postId, updatedData) => {
  try {
    const postIdStr = String(postId);
    console.log(`Attempting to update post ${postIdStr} with data:`, updatedData); // Enhanced logging

    const postRef = doc(db, 'posts', postIdStr);
    const postDoc = await getDoc(postRef);

    if (!postDoc.exists()) {
      console.error(`Post with ID ${postIdStr} does not exist.`);
      throw new Error(`Post with ID ${postIdStr} does not exist.`);
    }

    await updateDoc(postRef, updatedData);
    console.log('Post successfully updated in Firestore');
    console.log('Updated Post Data:', updatedData); // Log the updated data
  } catch (error) {
    console.error("Error updating post in Firestore", error);
    throw error; // Re-throwing the error to be handled by the caller
  }
};

// Function to get a user by ID
export const getUserById = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      // Instead of throwing an error, log the error and return null
      console.error(`User not found with ID ${userId}`);
      return null;
    }

    return { id: userDoc.id, ...userDoc.data() };
  } catch (error) {
    console.error("Error fetching user by ID from Firestore", error);
    // Instead of throwing an error, log the error and return null
    return null;
  }
};

// Function to update message read status
export const updateMessageReadStatus = async (conversationId, currentUsername) => {
  try {
    const q = query(collection(db, 'messages'), where('conversationId', '==', conversationId), where('receiver', '==', currentUsername), where('read', '==', false));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      const messageRef = doc.ref;
      await updateDoc(messageRef, { read: true });
    });
  } catch (error) {
    console.error("Error updating message read status", error);
    throw new Error("Error updating message read status");
  }
};

// Function to save contact submission
export const saveContactSubmission = async (submissionData) => {
  try {
    await saveDataToDB('contactSubmissions', submissionData);
  } catch (error) {
    console.error("Error saving contact submission to Firestore", error);
    throw new Error("Error saving contact submission to Firestore");
  }
};

// Function to get all contact submissions
export const getAllContactSubmissions = async () => {
  try {
    return await getAllDataFromDB('contactSubmissions');
  } catch (error) {
    console.error("Error fetching all contact submissions from Firestore", error);
    throw new Error("Error fetching all contact submissions from Firestore");
  }
};

// Function to mark post as reported
export const markPostAsReported = async (postId) => {
  try {
    if (typeof postId !== 'string') {
      throw new Error("Invalid postId: expected a string");
    }
    const postRef = doc(db, 'posts', postId);
    const postDoc = await getDoc(postRef);
    if (!postDoc.exists()) {
      console.error(`Post with ID ${postId} does not exist.`);
      return; // Or handle based on your application's needs
    }
    await updateDoc(postRef, { reported: true });
  } catch (error) {
    console.error("Error marking post as reported in Firestore", error);
    throw new Error("Error marking post as reported in Firestore");
  }
};

// Function to get reported posts
export const getReportedPosts = async () => {
  try {
    const q = query(collection(db, 'posts'), where('reported', '==', true));
    const querySnapshot = await getDocs(q);
    const reportedPosts = [];
    querySnapshot.forEach((doc) => {
      reportedPosts.push({ id: doc.id, ...doc.data() });
    });
    return reportedPosts;
  } catch (error) {
    console.error("Error fetching reported posts from Firestore", error);
    throw new Error("Error fetching reported posts from Firestore");
  }
};

// Function to mark post as reviewed
export const markPostAsReviewed = async (postId) => {
  try {
    await updatePostInDB(postId, { reported: false });
  } catch (error) {
    console.error("Error marking post as reviewed in Firestore", error);
    throw new Error("Error marking post as reviewed in Firestore");
  }
};

// Function to update the post verification password
export const updatePostVerificationPassword = async (newPassword) => {
  try {
    const settingsRef = doc(db, 'settings', 'postVerificationPassword');
    await setDoc(settingsRef, { value: newPassword });
  } catch (error) {
    console.error("Error updating post verification password in Firestore", error);
    throw new Error("Error updating post verification password in Firestore");
  }
};

// Function to get the post verification password
export const getPostVerificationPassword = async () => {
  try {
    const settingsRef = doc(db, 'settings', 'postVerificationPassword');
    const settingsDoc = await getDoc(settingsRef);
    if (settingsDoc.exists()) {
      return settingsDoc.data().value;
    } else {
      throw new Error("Post verification password not found");
    }
  } catch (error) {
    console.error("Error fetching post verification password from Firestore", error);
    throw new Error("Error fetching post verification password from Firestore");
  }
};

// Function to upload an image to Firebase Storage and get the download URL
export const uploadImageToStorage = async (image, path) => {
  console.log('uploadImageToStorage: Starting upload for path:', path);
  try {
    const imageRef = storageRef(storage, path);
    console.log('uploadImageToStorage: Uploading bytes...');
    await uploadBytes(imageRef, image);
    console.log('uploadImageToStorage: Getting download URL...');
    const downloadURL = await getDownloadURL(imageRef);
    console.log(`uploadImageToStorage: Image uploaded to path: ${path}`);
    console.log('uploadImageToStorage: Download URL:', downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("uploadImageToStorage: Error uploading image to Firebase Storage", error);
    throw new Error("uploadImageToStorage: Error uploading image to Firebase Storage");
  }
};

// Function to get posts by a user ID from Firestore
export const getPostsByUserId = async (userId) => {
  try {
    const q = query(collection(db, 'posts'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
      posts.push({ id: doc.id, ...doc.data() });
    });
    return posts;
  } catch (error) {
    console.error("Error fetching posts by user ID", error);
    throw new Error("Error fetching posts by user ID");
  }
};

// Function to get a post by its ID from Firestore
export const getPostById = async (postId) => {
  try {
    // Ensure postId is a string
    const postIdStr = String(postId);
    const postRef = doc(db, 'posts', postIdStr);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      console.error(`No post found with ID ${postIdStr}`);
      return null; // Return null if the post doesn't exist
    }

    return { id: postSnapshot.id, ...postSnapshot.data() };
  } catch (error) {
    console.error("Error fetching post by ID from Firestore", error);
    return null; // Return null in case of error
  }
};

// Function to mark report as reviewed
export const markReportAsReviewed = async (reportId) => {
  try {
    const reportRef = doc(db, 'reports', reportId);
    await updateDoc(reportRef, { reviewed: true });
    console.log(`Report with ID ${reportId} marked as reviewed in Firestore`);
  } catch (error) {
    console.error("Error marking report as reviewed in Firestore", error);
    throw new Error("Error marking report as reviewed in Firestore");
  }
};

// Function to mark report as post deleted
export const markReportAsDeleted = async (reportId) => {
  try {
    const reportRef = doc(db, 'reports', reportId);
    await updateDoc(reportRef, { postDeleted: true });
    console.log(`Report with ID ${reportId} marked as post deleted in Firestore`);
  } catch (error) {
    console.error("Error marking report as post deleted in Firestore", error);
    throw new Error("Error marking report as post deleted in Firestore");
  }
};

// Function to check if a conversation already exists
export const checkConversationExists = async (conversationId) => {
  const q = query(collection(db, 'conversations'), where('id', '==', conversationId));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
};

// Function to create a new conversation
export const createConversation = async (conversationId, participants) => {
  try {
    await setDoc(doc(db, 'conversations', conversationId), { participants });
    console.log(`Conversation ${conversationId} created successfully`);
  } catch (error) {
    console.error("Error creating conversation", error);
    throw new Error("Error creating conversation");
  }
};

// Function to get the last submission from a specific collection
export const getLastSubmission = async (collectionName) => {
  try {
      const q = query(collection(db, collectionName), orderBy('timestamp', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
          // Assuming the document contains a field named 'imageUrl'
          return querySnapshot.docs[0].data(); // Return the most recent submission data
      }
      return null; // Return null if there are no documents
  } catch (error) {
      console.error("Error getting last submission from Firestore", error);
      throw new Error("Error getting last submission from Firestore");
  }
};

// Function to delete an image from Firebase Storage
export const deleteImageFromStorage = async (path) => {
  const imageRef = storageRef(storage, path);
  try {
    await deleteObject(imageRef);
    console.log(`Image successfully deleted from path: ${path}`);
  } catch (error) {
    // Check if the error code is 'storage/object-not-found'
    if (error.code === 'storage/object-not-found') {
      console.warn(`Image not found in Firebase Storage, continuing deletion process: ${path}`);
    } else {
      // If it's a different error, then throw it
      console.error("Error deleting image from Firebase Storage", error);
      throw new Error("Error deleting image from Firebase Storage");
    }
  }
};
