// ReportForm.js

import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { PostsContext } from '../contexts/PostsContext';
import { ReportsContext } from '../contexts/ReportsContext'; 
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';

const ReportForm = ({ postId, closeModal }) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [otherDetails, setOtherDetails] = useState('');
  const [reportSubmitted, setReportSubmitted] = useState(false);
  const [mathChallenge, setMathChallenge] = useState({});
  const [mathAnswer, setMathAnswer] = useState('');
  const [error, setError] = useState(null);

  const { currentUser } = useAuth();
  const { updateReportedStatus } = useContext(PostsContext);
  const { reportPost } = useContext(ReportsContext);

  useEffect(() => {
    generateMathChallenge();
  }, []);

  const generateMathChallenge = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setMathChallenge({ num1, num2 });
  };

  const handleOtherDetailsChange = (e) => {
    const words = e.target.value.split(' ').slice(0, 8);
    setOtherDetails(words.join(' '));
  };

  const submitReport = async (event) => {
    event.preventDefault();
    if (parseInt(mathAnswer) !== mathChallenge.num1 + mathChallenge.num2) {
      setError('Incorrect answer to math problem.');
      generateMathChallenge();
      return;
    }

    if (!currentUser) {
      setError('User not authenticated.');
      return;
    }

    const timestampISO = new Date().toISOString();
    const reportDetails = {
      postId,
      userId: currentUser.uid,
      reason: selectedReason,
      text: selectedReason === 'Other' ? otherDetails : '',
      timestamp: timestampISO
    };

    try {
      const reportId = await reportPost(reportDetails);
      console.log(`Report successfully saved with ID: ${reportId}`);
      try {
        await updateReportedStatus(postId, true);
        setReportSubmitted(true);
      } catch (updateError) {
        console.error('Error updating post status:', updateError);
        setError('Error occurred while updating the post status.');
      }
    } catch (reportError) {
      console.error('Error reporting post:', reportError);
      setError('Error occurred while reporting the post.');
    }
  };

  if (reportSubmitted) {
    return (
      <div className="modal-background">
        <div className="report-form-modal">
          <div className="report-confirmation">
            <h2>Thank you for your report!</h2>
            <p>We will review your report as soon as possible.</p>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-background">
      <div className="report-form-modal">
        <div className="report-form-header">
          <button onClick={closeModal} className="close-modal-button">✕</button>
          <h2>Select a reason for reporting this post:</h2>
        </div>
        <form onSubmit={submitReport} className="report-form">
          <div className="report-reasons">
            <label>
              <input
                type="radio"
                name="reportReason"
                value="Sexual Content"
                checked={selectedReason === 'Sexual Content'}
                onChange={(e) => setSelectedReason(e.target.value)}
              />
              Sexual Content
            </label>
            <label>
              <input
                type="radio"
                name="reportReason"
                value="Violent or Repulsive Content"
                checked={selectedReason === 'Violent or Repulsive Content'}
                onChange={(e) => setSelectedReason(e.target.value)}
              />
              Violent or Repulsive Content
            </label>
            <label>
              <input
                type="radio"
                name="reportReason"
                value="Hateful or Abusive Content"
                checked={selectedReason === 'Hateful or Abusive Content'}
                onChange={(e) => setSelectedReason(e.target.value)}
              />
              Hateful or Abusive Content
            </label>
            <label>
              <input
                type="radio"
                name="reportReason"
                value="Spam or Misleading"
                checked={selectedReason === 'Spam or Misleading'}
                onChange={(e) => setSelectedReason(e.target.value)}
              />
              Spam or Misleading
            </label>
            <label>
              <input
                type="radio"
                name="reportReason"
                value="Other"
                checked={selectedReason === 'Other'}
                onChange={(e) => setSelectedReason(e.target.value)}
              />
              Other
            </label>
            {selectedReason === 'Other' && (
              <textarea
                className="other-details-textarea"
                placeholder="Please provide more details (max 8 words)."
                value={otherDetails}
                onChange={handleOtherDetailsChange}
              />
            )}
          </div>
          <div>
            Solve this to prove you're not a robot: {mathChallenge.num1} + {mathChallenge.num2} = ?
            <TextField
              fullWidth
              variant="outlined"
              value={mathAnswer}
              onChange={(e) => setMathAnswer(e.target.value)}
              error={error !== null && error.includes('math problem')}
              helperText={error && error.includes('math problem') ? error : ''}
              sx={{ '& .MuiInputBase-input': { textAlign: 'center' } }}
              margin="normal"
            />
          </div>
          <button type="submit" className="report-button">Report Post</button>
        </form>
      </div>
    </div>
  );
};

export default ReportForm;



