// Footer.js

import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaHouseUser, FaPlusSquare, FaCompass } from 'react-icons/fa';
import FiberNewIcon from '@mui/icons-material/FiberNew';

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-icons">
        <Link to="/" className="footer-icon"><FaHouseUser /></Link>
        <Link to="/post" className="footer-icon"><FaPlusSquare /></Link>
        {/* Apply the new class to the 'New' button icon */}
        <Link to="/newposts" className="footer-icon footer-icon--new"><FiberNewIcon /></Link>
        <Link to="/explore" className="footer-icon"><FaCompass /></Link>
      </div>
    </footer>
  );
}

export default Footer;
