// MessagesPage.js

import React, { useEffect, useState } from 'react';
import MessagesList from './MessagesList';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './MessagesPage.css';
import { useMessages } from '../contexts/MessagesContext';

function MessagesPage() {
  const navigate = useNavigate();
  const { fetchMessages, isLoading } = useMessages();
  const [topMargin, setTopMargin] = useState('40px');

  useEffect(() => {
    fetchMessages(); // Fetch messages when the component mounts
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts

    // Adjust the top margin based on screen width
    const updateTopMargin = () => {
      const width = window.innerWidth;
      if (width >= 1920 && width <= 2560) {
        setTopMargin('250px'); // Asus Zenbook Fold
      } else if (width >= 1368 && width <= 1368) {
        setTopMargin('200px'); // Surface Pro 7
      } else if (width >= 1264) {
        setTopMargin('100px'); // Screen widths 1264px and higher
      } else if (width >= 1280 && width <= 800) {
        setTopMargin('180px'); // Nest Hub Max
      } else if (width >= 1024 && width <= 600) {
        setTopMargin('160px'); // Nest Hub
      } else if (width >= 1024 && width <= 1366) {
        setTopMargin('220px'); // iPad Pro
      } else if (width >= 820 && width <= 1180) {
        setTopMargin('200px'); // iPad Air
      } else if (width >= 768 && width <= 1024) {
        setTopMargin('180px'); // iPad Mini
      } else if (width <= 768) {
        setTopMargin('160px'); // Smaller screens
      } else {
        setTopMargin('80px'); // Default top margin
      }
    };

    // Listen for window resize events
    window.addEventListener('resize', updateTopMargin);

    // Set the initial margin based on the current window size
    updateTopMargin();

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', updateTopMargin);
  }, []); // Remove fetchMessages from the dependency array

  const handleNewChat = () => {
    navigate('/chat'); // Updated to the correct path
  };

  if (isLoading) {
    return <div>Loading messages...</div>;
  }

  return (
    <div className="messages-container" style={{ marginTop: topMargin }}>
      <div className="header">
        {/* header content */}
      </div>
      <div className="main-content">
        <MessagesList />
        <div className="new-chat-button-section">
          <Button variant="contained" color="primary" onClick={handleNewChat}>
            New Chat
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MessagesPage;
