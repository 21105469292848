// SearchResultsPage.js

import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Post from './Post';
import { getAllDataFromDB } from '../utils/dbUtilities';
import './Post.css';

const SearchResultsPage = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('query');

  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Search Query from URL:", searchQuery);
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const posts = await getAllDataFromDB('posts');
        console.log("All posts from DB:", posts);

        const queryLower = searchQuery ? searchQuery.toLowerCase() : '';
        const filteredResults = posts.filter(post =>
          post.title.toLowerCase().includes(queryLower) ||
          post.author.toLowerCase().includes(queryLower) ||
          (post.description && post.description.toLowerCase().includes(queryLower))
        );

        console.log("Filtered results:", filteredResults);
        setResults(filteredResults);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setError('Error fetching search results');
      } finally {
        setIsLoading(false);
      }
    };

    if (searchQuery) {
      fetchData();
    }
  }, [searchQuery]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="search-results-container">
      {results.length === 0 ? (
        <p>No results found for "{searchQuery}"</p>
      ) : (
        results.map(result => <Post key={result.id} {...result} />)
      )}
    </div>
  );
};

export default SearchResultsPage;

