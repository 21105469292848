// utils/timeFormatter.js

import { Timestamp } from 'firebase/firestore'; // Import Firestore Timestamp

function parseDate(input) {
  let date;
  if (input instanceof Timestamp) {
    date = input.toDate(); // Convert Firestore Timestamp to JavaScript Date object
  } else if (typeof input === 'string') {
    date = new Date(input); // Parse string date to JavaScript Date object
  } else {
    date = input; // Assume it's already a JavaScript Date object
  }
  return date;
}

export function timeAgo(timestamp) {
  const date = parseDate(timestamp);

  if (isNaN(date.getTime())) {
    return 'Time unknown'; // Return a default message if date is invalid
  }

  const now = Date.now();
  const difference = now - date.getTime();

  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;

  if (difference < minute) {
    return 'just now';
  } else if (difference < hour) {
    const minutes = Math.floor(difference / minute);
    return minutes + (minutes === 1 ? ' min ago' : ' mins ago');
  } else if (difference < day) {
    const hours = Math.floor(difference / hour);
    return hours + (hours === 1 ? ' hour ago' : ' hours ago');
  } else {
    const days = Math.floor(difference / day);
    return days + (days === 1 ? ' day ago' : ' days ago');
  }
}