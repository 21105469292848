// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { analytics } from './utils/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import PostVerification from './components/PostVerification';
import SearchResultsPage from './components/SearchResultsPage';
import ChatWindow from './components/ChatWindow';
import HomePage from './components/HomePage';
import NewPosts from './components/NewPosts';
import LoginPage from './components/LoginPage';
import MessagesPage from './components/MessagesPage';
import SignUpPage from './components/SignUpPage';
import ViewReports from './components/ViewReports';
import LikesPage from './components/LikesPage';
import MyPostsPage from './components/MyPostsPage';
import InfoPage from './components/InfoPage';
import ContactForm from './components/ContactForm';
import ChatComponent from './components/ChatComponent';
import ExplorePage from './components/ExplorePage';
import AdminDashboard from './components/AdminDashboard';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ManagePosts from './components/ManagePosts';
import PostPage from './components/PostPage';
import ProfilePage from './components/ProfilePage';
import UsersPosts from './components/UsersPosts';
import EditPostForm from './components/EditPostForm';
import PostForm from './components/PostForm';
import SuggestionGenerator from './components/SuggestionGenerator';
import ReportForm from './components/ReportForm';
import { ProtectedRoute } from './components/ProtectedRoute';
import { PostsProvider } from './contexts/PostsContext';
import { AuthProvider } from './contexts/AuthContext';
import { LikesProvider } from './contexts/LikesContext';
import ActiveItemContext from './contexts/ActiveItemContext';
import { MessagesProvider } from './contexts/MessagesContext';
import UsernamesContext from './contexts/UsernamesContext';
import { ReportsProvider } from './contexts/ReportsContext';
import { BlockProvider } from './contexts/BlockContext';
import { UsersProvider } from './contexts/UsersContext';
import './App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { db } from './utils/firebaseConfig';

function PageViewTracker() {
  let location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title,
    });
  }, [location]);

  return null;
}

function App() {
  const [activeItem, setActiveItem] = useState('Home');
  const [usernames, setUsernames] = useState({});
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const fetchAndSyncUsers = async () => {
      try {
        const usersCol = collection(db, 'users');
        const userSnapshot = await getDocs(usersCol);
        const users = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsernames(users.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.username }), {}));
      } catch (error) {
        console.error("Error fetching users from Firestore:", error);
      }
    };
    fetchAndSyncUsers();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsSidebarVisible(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AuthProvider>
      <BlockProvider>
        <PostsProvider>
          <LikesProvider>
            <MessagesProvider>
              <ReportsProvider>
                <UsersProvider>
                  <UsernamesContext.Provider value={{ usernames, setUsernames }}>
                    <ActiveItemContext.Provider value={{ activeItem, setActiveItem }}>
                        <Router>
                          <PageViewTracker />
                          <div className="App">
                            <Navbar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />
                            <Container maxWidth="lg">
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={8} lg={isLargeScreen ? 8 : 9}>
                                  <Routes>
                                    <Route path="/" element={<HomePage />} />
                                    <Route path="/post" element={<PostVerification />} />
                                    <Route path="/postform" element={<PostForm />} />
                                    <Route path="/newposts" element={<NewPosts />} />
                                    <Route path="/explore" element={<ExplorePage />} />
                                    <Route path="/suggestions" element={<SuggestionGenerator />} />
                                    <Route path="/posts/:id" element={<PostPage />} />
                                    <Route path="/messages" element={<MessagesPage />} />
                                    <Route path="/login" element={<LoginPage />} />
                                    <Route path="/signup" element={<SignUpPage />} />
                                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                                    <Route path="/profile" element={<ProfilePage />} />
                                    <Route path="/likes" element={<LikesPage />} />
                                    <Route path="/myposts" element={<MyPostsPage />} />
                                    <Route path="/usersposts/:username" element={<UsersPosts />} />
                                    <Route path="/search" element={<SearchResultsPage />} />
                                    <Route path="/chat/:username" element={<ChatComponent />} />
                                    <Route path="/chat" element={<ChatWindow />} />
                                    <Route path="/editpost/:id" element={<EditPostForm />} />
                                    <Route path="/contact" element={<ContactForm />} />
                                    <Route path="/admin-dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminDashboard /></ProtectedRoute>} />
                                    <Route path="/info" element={<InfoPage />} />
                                    <Route path="/manage-posts" element={<ManagePosts />} />
                                    <Route path="/view-reports" element={<ViewReports />} />
                                  </Routes>
                                </Grid>
                                {isLargeScreen && <Grid item lg={1} />} {/* This acts as a spacer */}
                                <Grid item xs={12} md={4} lg={3}>
                                  <Sidebar isVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />
                                </Grid>
                              </Grid>
                            </Container>
                            <Footer />
                          </div>
                        </Router>
                    </ActiveItemContext.Provider>
                  </UsernamesContext.Provider>
                </UsersProvider>
              </ReportsProvider>
            </MessagesProvider>
          </LikesProvider>
        </PostsProvider>
      </BlockProvider>
    </AuthProvider>
  );  
}

export default App;
