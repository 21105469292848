// SuggestionGenerator.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, TextField, Button, MenuItem, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './SuggestionGenerator.css';
import { useAuth } from '../contexts/AuthContext';

const SuggestionGenerator = () => {
    const [input, setInput] = useState('');
    const [category, setCategory] = useState('');
    const [timeframe, setTimeframe] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    useEffect(() => {
        const savedInput = localStorage.getItem(`input_${currentUser?.uid}`) || '';
        const savedCategory = localStorage.getItem(`category_${currentUser?.uid}`) || '';
        const savedTimeframe = localStorage.getItem(`timeframe_${currentUser?.uid}`) || '';
        const savedSuggestions = JSON.parse(localStorage.getItem(`suggestions_${currentUser?.uid}`)) || [];

        setInput(savedInput);
        setCategory(savedCategory);
        setTimeframe(savedTimeframe);
        setSuggestions(savedSuggestions);
    }, [currentUser?.uid]);

    useEffect(() => {
        return () => {
            localStorage.setItem(`input_${currentUser?.uid}`, input);
            localStorage.setItem(`category_${currentUser?.uid}`, category);
            localStorage.setItem(`timeframe_${currentUser?.uid}`, timeframe);
        };
    }, [input, category, timeframe, currentUser?.uid]);

    const normalizeText = (text) => {
        return text.replace(/['"]/g, '').trim();
    };

    const handleGenerateSuggestions = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');

        if (!input && !category && !timeframe) {
            setError('Please enter or select at least one field to generate suggestions.');
            setIsLoading(false);
            return;
        }

        const parts = [];
        if (input) parts.push(`related to "${normalizeText(input)}"`);
        if (category) parts.push(`in the category "${normalizeText(category)}"`);
        if (timeframe) parts.push(`from "${normalizeText(timeframe)}"`);

        let promptPart = parts.join(' and ');
        if (promptPart) promptPart = ` for unique items ${promptPart}`;

        const prompt = `Please provide four suggestions${promptPart} that are not widely available for sale, in bullet point format. Each suggestion should be max 10 word sentences and should not include quotations.`;

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                    model: "gpt-3.5-turbo-instruct",
                    prompt: prompt,
                    max_tokens: 200,
                    temperature: 0.7,
                    n: 1,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
                    }
                }
            );
            const suggestionsText = response.data.choices[0].text.trim();
            const newSuggestionsList = suggestionsText.split('\n')
                .filter(item => item)
                .map(item => item.replace(/^\s*(\d+\.\s*|\-\s*|\s*)/, ''))
                .map(suggestion => normalizeText(suggestion.replace(/\.(\s*\(Images\))?$/, '')));
            setSuggestions(newSuggestionsList);
            localStorage.setItem(`suggestions_${currentUser.uid}`, JSON.stringify(newSuggestionsList));
        } catch (error) {
            console.error('Error generating suggestions:', error);
            setError('Error generating suggestions. Please try again later.');
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClearSuggestions = () => {
        setSuggestions([]);
        setInput('');
        setCategory('');
        setTimeframe('');
        localStorage.removeItem(`suggestions_${currentUser.uid}`);
        localStorage.removeItem(`input_${currentUser.uid}`);
        localStorage.removeItem(`category_${currentUser.uid}`);
        localStorage.removeItem(`timeframe_${currentUser.uid}`);
    };

    const shouldShowClearButton = input || category || timeframe || suggestions.length > 0;

    if (!currentUser) {
        navigate('/login');
        return null;
    }

    return (
        <div className="suggestion-form-container">
            <Container component="main" maxWidth="sm" className="suggestion-form" style={{ maxWidth: '400px' }}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            AI Generated Post Suggestions
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Fill in at least one (1)
                        </Typography>
                        <form onSubmit={handleGenerateSuggestions}>
                            <TextField
                                label="Keyword(s)"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                SelectProps={{
                                    displayEmpty: true,
                                }}
                            />
                            <TextField
                                label="Category"
                                variant="outlined"
                                select
                                fullWidth
                                margin="normal"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                SelectProps={{
                                    displayEmpty: true,
                                }}
                            >
                                {[
                                    'Clothing, Accessories & Fashion',
                                    'Electronics',
                                    'Music',
                                    'Entertainment',
                                    'Household Items',
                                    'Children',
                                    'Outdoors',
                                    'Collectibles',
                                    'Art',
                                    'Books',
                                    'Sports',
                                    'Jewelry & Watches',
                                    'Health & Beauty',
                                    'Pets',
                                    'Business & Commerce'
                                ].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Timeframe"
                                variant="outlined"
                                select
                                fullWidth
                                margin="normal"
                                value={timeframe}
                                onChange={(e) => setTimeframe(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                SelectProps={{
                                    displayEmpty: true,
                                }}
                            >
                                {['Current', '2000s', '1990s', '1980s', 'Vintage'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <div className={`buttons-container ${shouldShowClearButton ? 'two-buttons' : ''}`}>
                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    color="primary"
                                    className="action-button"
                                    disabled={!input && !category && !timeframe || isLoading}
                                >
                                    {isLoading ? "Generating..." : "Go"}
                                </Button>
                                {shouldShowClearButton && (
                                    <Button 
                                        type="button" 
                                        variant="contained" 
                                        color="secondary"
                                        onClick={handleClearSuggestions}
                                        className="action-button"
                                    >
                                        Clear
                                    </Button>
                                )}
                            </div>
                        </form>
                        {error && <Typography color="error">{error}</Typography>}
                        {suggestions.length > 0 && (
                            <div className="suggestions-list">
                                {suggestions.map((suggestion, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <Typography variant="body1" style={{ display: 'inline' }}>
                                            <a
                                                href="#"
                                                onClick={(e) => { 
                                                    e.preventDefault(); 
                                                    navigate('/postform', { state: { suggestionText: suggestion } });
                                                }}
                                                className="suggestion-link"
                                            >
                                                {suggestion}
                                            </a>
                                            <a
                                                href={`https://www.google.com/search?tbm=isch&q=${encodeURIComponent(suggestion)}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="image-link"
                                            >
                                                (Images)
                                            </a>
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Container>
        </div>
    );              
};

export default SuggestionGenerator;
